import { useRef, useState } from "react";

// mui
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const OTPInput = ({
  length,
  onChange,
  placeholder,
  inputPad,
  allowedType = "alphanumeric",
  ...props
}) => {
  const inputs = useRef([]);
  const [errors, setErrors] = useState(Array.from({ length }).fill(false));

  const getAllowedPattern = () => {
    switch (allowedType) {
      case "alphabets":
        return /[^A-Z]/g; // Only alphabets
      case "numbers":
        return /[^0-9]/g; // Only numbers
      case "alphanumeric":
      default:
        return /[^A-Z0-9]/g; // Alphanumeric
    }
  };

  const handleChange = (e, index) => {
    let value = e.target.value.toUpperCase();
    const allowedPattern = getAllowedPattern();
    value = value.replace(allowedPattern, ""); // Allow only based on allowedType
    e.target.value = value;

    let otp = "";
    const newErrors = [...errors];
    inputs.current.forEach((input, i) => {
      otp += input.value.toUpperCase();
      if (i === index) {
        newErrors[i] = input.value.trim() === "";
      }
    });

    // onChange with the full OTP
    onChange(otp);

    if (index < length - 1 && value.length === 1) {
      inputs.current[index + 1].focus();
    }
    setErrors(newErrors);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      inputs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text/plain").toUpperCase();
    const allowedPattern = getAllowedPattern();

    // Remove non-allowed characters from pasted text
    const sanitizedText = pastedText.replace(allowedPattern, "");

    // Reset errors for all fields
    const newErrors = Array.from({ length }).fill(false);

    // Fill OTP fields with sanitized text, leaving any non-alphanumeric characters in place
    Array.from(sanitizedText).forEach((char, index) => {
      if (index < length) {
        inputs.current[index].value = char;
        handleChange({ target: { value: char } }, index);
      }
    });

    // Update errors state
    setErrors(newErrors);
  };

  return (
    <Box display="flex" my={1} justifyContent="center" sx={{ gap: 2 }}>
      {Array.from({ length }).map((_, index) => (
        <TextField
          key={index}
          variant="outlined"
          placeholder={placeholder}
          error={errors[index]}
          helperText={errors[index] ? "Required" : ""}
          InputProps={{
            sx: { py: inputPad || "20px", borderRadius: 1.5 },
          }}
          inputProps={{
            maxLength: 1,
            sx: {
              textAlign: "center",
              textTransform: "uppercase",
              color: "#000000",
            },
          }}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          inputRef={(el) => (inputs.current[index] = el)}
          {...props}
        />
      ))}
    </Box>
  );
};

export default OTPInput;
