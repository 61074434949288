export const REPORT = "REPORT";
export const REPORT_VALUE = "REPORT_VALUE";
export const RESET_FIELD_DATA = "RESET_FIELD_DATA";
export const SET_VIEW_SURVEY = "SET_VIEW_SURVEY";
export const SET_SURVEY_TYPE = "SET_SURVEY_TYPE";
export const SET_SURVEY_RESULT = "SET_SURVEY_RESULT";
export const SET_SURVEY_ID = "SET_SURVEY_ID";
export const SET_SURVEY_DATA = "SET_SURVEY_DATA";
export const SET_CURRENT_METRIC_ID = "SET_CURRENT_METRIC_ID";
export const SET_ALL_STEP_5_USERS = "SET_ALL_STEP_5_USERS";

export const SET_UPSERT = "SET_UPSERT";

export const SET_TOPIC_METRICS = "SET_TOPIC_METRICS";
export const SET_REPORT_METRICS = "SET_REPORT_METRICS";
export const SET_REPORT_TAB = "SET_REPORT_TAB";
export const SET_REVIEW_DATA = "SET_REVIEW_DATA";
export const SET_UPSER_METRIC_DATA = "SET_UPSER_METRIC_DATA";
export const SET_CURRENT_METRIC = "SET_CURRENT_METRIC";
export const SET_CURRENT_DETAILED_METRIC = "SET_CURRENT_DETAILED_METRIC";

export const SET_REPORT_DATA_TASK = "SET_REPORT_DATA_TASK";
export const SET_ASSIGNED_METRIC = "SET_ASSIGNED_METRIC";
export const SET_ASSIGNED_MEMBERS_FIELD = "SET_ASSIGNED_MEMBERS_FIELD";
export const SET_FREEZE_LOADING = "SET_FREEZE_LOADING";
export const SET_FREEZE_UPLOADED = "SET_FREEZE_UPLOADED";

export const SET_METRIC_RESULT = "SET_METRIC_RESULT";

export const SET_BLOCKCHAIN_HASHLINKS = "SET_BLOCKCHAIN_HASHLINKS";

export const SET_CURRENT_REVIEW_METRICS = "SET_CURRENT_REVIEW_METRICS";
export const SET_REVIEW_METRICS = "SET_REVIEW_METRICS";
export const SET_COLLECT_METRICS = "SET_COLLECT_METRICS";
export const SET_ASSIGNED_METRIC_USERS = "SET_ASSIGNED_METRIC_USERS";
export const SET_FRAMEWORK_DEFAULT_FILTER = "SET_FRAMEWORK_DEFAULT_FILTER";
export const RESET_TEMPLATE_TYPE = "RESET_TEMPLATE_TYPE";
export const FILTER = "FILTER";
export const SET_DISCLOSURE_SETTINGS_FILES = "SET_DISCLOSURE_SETTINGS_FILES";
export const SET_DISCLOSURE_SETTINGS_DATA = "SET_DISCLOSURE_SETTINGS_DATA";
export const SET_DISCLOSURE_SETTINGS_VIEW_SUBMISSION =
  "SET_DISCLOSURE_SETTINGS_VIEW_SUBMISSION";

//constants for the chat
export const SET_METRIC_CHATS = "SET_METRIC_CHATS";
export const SET_DISCLOSURE_SETTINGS_RESPONSES =
  "SET_DISCLOSURE_SETTINGS_RESPONSES";
