import {
  SET_COMMENTS_TOC,
  SET_COMMENTS,
} from "../../constants/comments/commentsCont";

const commentState = {
  commentToc: [],
  comments: [],
};

export default function comments(state = commentState, action) {
  switch (action.type) {
    case SET_COMMENTS_TOC:
      return { ...state, commentToc: action.payload };
    case SET_COMMENTS:
      return { ...state, comments: action.payload };
    default:
      return state;
  }
}
