// ----------------------------------------------------------------------

export default function Menu(theme) {
  return {
    MuiMenu: {
      defaultProps: {
        disableScrollLock: true,
      },
      styleOverrides: {
        paper: {
          boxShadow: "0px 4px 4px 0px #00000040",
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          padding: "15px 40px",
          "&:hover": {
            color: theme.palette.primary.main,
            backgroundColor: "transparent",
          },
          // "&.Mui-selected": {
          //   backgroundColor: theme.palette.primary.lighter,
          //   "&:hover": {
          //     backgroundColor: theme.palette.primary.light,
          //   },
          // },
        },
      },
    },
  };
}
