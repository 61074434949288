import { useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import requiredField from "components/UI/phase-3/inputField/requiredShow";
import useFormValidation from "customHooks/useFormValidation";
// redux
import { useDispatch } from "react-redux";
// icons
import { LuEye, LuEyeOff } from "react-icons/lu";
import { userSignup } from "actions/phase-3/auth/authAction";
import { Link, useNavigate} from "react-router-dom";

const SignupForm = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  // states
  const [showPassword, setShowPassword] = useState(false);

  const initialState = {
    name: "",
    entity_name: "",
    email: "",
    password: "",
  };

  const validationRules = {
    name: { minCharacters: 3, maxCharacters: 30, required: true },
    entity_name: { minCharacters: 3, maxCharacters: 30, required: true },
    email: { type: "email", required: true },
    password: { type: "password", required: true },
  };

  const { formData, errors, handleChange, handleSubmit, hasErrors } =
    useFormValidation(initialState, validationRules);

  const disableSubmit =
    !formData.name || !formData.email || !formData.password || hasErrors;

  const redirectToVerify = () => {
    navigate("/verify");
  };
 const handletnc=()=>{
  window.open("/tnc")
 }
 const handlePvc=()=>{
     window.open("/privacy_policy")
 }
  const onSubmit = () => {
    dispatch(
      userSignup(
        formData.name,
        formData.email,
        formData.password,
        formData.entity_name,
        redirectToVerify
      )
    );
  };

  return (
    <form onSubmit={(e) => handleSubmit(e, onSubmit)}>
      <Stack spacing={2}>
        {/* Name */}
        <Box>
          <Typography variant="body1" color="action.disabled" gutterBottom>
            Your Name {requiredField(Boolean(errors.name) || !formData.name)}
          </Typography>
          <TextField
            name="name"
            placeholder="Enter Name Here"
            value={formData.name}
            onChange={handleChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
            required
            fullWidth
          />
        </Box>

        {/* Company Name */}
        <Box>
          <Typography variant="body1" color="action.disabled" gutterBottom>
            Company Name
            {requiredField(
              Boolean(errors.entity_name) || !formData.entity_name
            )}
          </Typography>
          <TextField
            name="entity_name"
            placeholder="Company Name"
            value={formData.entity_name}
            onChange={handleChange}
            error={Boolean(errors.entity_name)}
            helperText={errors.entity_name}
            required
            fullWidth
          />
        </Box>

        {/* Work Email */}
        <Box>
          <Typography variant="body1" color="action.disabled" gutterBottom>
            Work Email {requiredField(Boolean(errors.email) || !formData.email)}
          </Typography>
          <TextField
            name="email"
            type="email"
            placeholder="Enter Work Email Here"
            value={formData.email}
            onChange={handleChange}
            error={Boolean(errors.email)}
            helperText={errors.email}
            required
            fullWidth
          />
        </Box>

        {/* Create Password */}
        <Box>
          <Typography variant="body1" color="action.disabled" gutterBottom>
            Create Password{" "}
            {requiredField(Boolean(errors.password) || !formData.password)}
          </Typography>
          <TextField
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter Password Here"
            value={formData.password}
            onChange={handleChange}
            error={Boolean(errors.password)}
            helperText={errors.password}
            required
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    sx={{ color: "primary.main" }}
                  >
                    {showPassword ? <LuEye /> : <LuEyeOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {/* Actions */}
        <Stack spacing={2} textAlign={"center"}>
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={disableSubmit}
            fullWidth
          >
            Create Account
          </Button>
          <Box>
            <Typography variant="body1" color={"action.disabled"}>
              Aleady have an account?{" "}
              <Button
                component={Link}
                to={"/login"}
                variant="text"
                sx={{ color: "primary.light", p: 0 }}
              >
                Login here
              </Button>
            </Typography>
            <Typography variant="body1" color={"action.disabled"}>
              <Button variant="text" sx={{ color: "primary.light", p: 0 }} 
              onClick={handletnc}>
                Terms of services
              </Button>{" "}
              and{" "}
              <Button variant="text" sx={{ color: "primary.light", p: 0 }}
              onClick={handlePvc}>
                Privacy Policy
              </Button>
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </form>
  );
};

export default SignupForm;
