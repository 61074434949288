import {
  SET_SINGLE_SHARED_SURVEY,
  SET_SUPPLIER_ASSIGNED_QUESTIONS_FOR_APPROVAL,
  SET_SUPPLIER_ASSIGNED_QUESTIONS_FOR_CONTRIBUTOR,
  SET_SUPPLIER_ASSIGNED_SURVEYS,
  SET_SUPPLIER_SHARED_SURVEYS,
  SET_SUPPLIER_SINGLE_SURVEY_DETAILS,
  SET_SUPPLIER_SINGLE_TASK_DETAILS,
  SET_EXPLORE_QUESTION_DETAILS,
  SET_SUPPLIER_ADMIN_STAKEHOLDERS,
  SET_SHARED_APPROVED_QUESTIONS,
} from "constants/phase-3/supplierAdmin/supplierAdminConst";

const initial_state = {
  supplierSharedSurveys: null,
  singleSharedSurvey: [],
  supplierAdminStakeholders: [],
  supplierAssignedSurveys: [],
  sharedApprovedQuestions: null,
  supplierSingleTaskDetails: null,
  supplierSingleSurveyDetails: null,
  exploreQuestionDetails: null,
  supplierAssignedQuestionsForContributor: [],
  supplierAssignedQuestionsForApproval: [],
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case "RESET_STATE":
      return (state = {
        ...state,
        ...initial_state,
      });
    case SET_SUPPLIER_SHARED_SURVEYS:
      return (state = {
        ...state,
        supplierSharedSurveys: action.payload,
      });
    case SET_SINGLE_SHARED_SURVEY:
      return (state = {
        ...state,
        singleSharedSurvey: action.payload,
      });
    case SET_SUPPLIER_ADMIN_STAKEHOLDERS:
      return (state = {
        ...state,
        supplierAdminStakeholders: action.payload,
      });
    case SET_SUPPLIER_ASSIGNED_SURVEYS:
      return (state = {
        ...state,
        supplierAssignedSurveys: action.payload,
      });
    case SET_SUPPLIER_SINGLE_TASK_DETAILS:
      return (state = {
        ...state,
        supplierSingleTaskDetails: action.payload,
      });
    case SET_SUPPLIER_SINGLE_SURVEY_DETAILS:
      return (state = {
        ...state,
        supplierSingleSurveyDetails: action.payload,
      });
    case SET_SUPPLIER_ASSIGNED_QUESTIONS_FOR_CONTRIBUTOR:
      return (state = {
        ...state,
        supplierAssignedQuestionsForContributor: action.payload,
      });
    case SET_SUPPLIER_ASSIGNED_QUESTIONS_FOR_APPROVAL:
      return (state = {
        ...state,
        supplierAssignedQuestionsForApproval: action.payload,
      });
    case SET_EXPLORE_QUESTION_DETAILS:
      return (state = {
        ...state,
        exploreQuestionDetails: action.payload,
      });
    case SET_SHARED_APPROVED_QUESTIONS:
      return (state = {
        ...state,
        sharedApprovedQuestions: action.payload,
      });
    default:
      return state;
  }
}
