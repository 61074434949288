import {
  CHAT_RESET_TIMER,
  CHAT_START_TIMER,
  CHAT_STOP_TIMER,
} from "constants/phase-3/chatTimers/chatTimerConstants";

const initial_state = {
  timers: {}, // key: question_id, value: timer ID
};

export default function chatTimerReducer(state = initial_state, action) {
  switch (action.type) {
    case "RESET_STATE":
      return (state = {
        ...state,
        ...initial_state,
      });
    case CHAT_START_TIMER:
      return {
        ...state,
        timers: {
          ...state.timers,
          [action.question_id]: action.timer_id,
        },
      };

    case CHAT_RESET_TIMER:
      return {
        ...state,
        timers: {
          ...state.timers,
          [action.question_id]: action.timer_id,
        },
      };

    case CHAT_STOP_TIMER:
      return {
        ...state,
        timers: {
          ...state.timers,
          [action.question_id]: null,
        },
      };

    default:
      return state;
  }
}
