import React, { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Hidden from "@mui/material/Hidden";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import SvgIcon from "@mui/material/SvgIcon";
import List from "@mui/material/List";

import { Navigate, useNavigate } from "react-router-dom";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import LinearProgress from "@mui/material/LinearProgress";
import CustomTextfield from "../../UI/textfield/textfield";
import CustomButton from "../../UI/button/button";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logoWhite from "../../../images/ImpactGrows White Corrected.svg";
import userAvatar from "../../../images/Avatar.svg";
import cert from "../../../images/cert.png";
import build from "../../../images/build.png";
// Image Imports
import BCD from "../../../images/bcd.png";
import SRG from "../../../images/srg.png";
import MA from "../../../images/ma.png";

import tick from "../../../images/tick.png";
import "../../../styles/hidescroll.css";
import CustomCheckBox from "../../UI/checkBox/checkBox";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import onBoardingUseStyles from "./onBoardingStyles";
import Autocomplete from "@mui/lab/Autocomplete/Autocomplete";
import ReactFlagsSelect from "react-flags-select";
import FrameworksDialog from "../reportdataleft/frameworksDialog";
import FrameworkContent from "../basicInformationRight/frameworkContent";
// Custom Chip
const myChip = createTheme(
  adaptV4Theme({
    overrides: {
      MuiChip: {
        outlinedPrimary: {
          color: "#ffffff",
          backgroundColor: "#32A05F",
          borderRadius: "5%",
          border: "0px",
          "$clickable&:hover, $deletable&:focus, $clickable&:focus": {
            color: "#ffffff",
            backgroundColor: "#32A05F",
          },
        },
        outlinedSecondary: {
          color: "#77787B",
          backgroundColor: "#F5F6F8",
          borderRadius: "5%",
          border: "0px",
          "$clickable&:hover, $deletable&:focus": {
            color: "#77787B",
            backgroundColor: "#F5F6F8",
          },
          "$clickable&:focus": {
            color: "#ffffff",
            backgroundColor: "#32A05F",
          },
        },
      },
    },
  })
);

// Custom ProgressBar
const myProgressBar = createTheme(
  adaptV4Theme({
    overrides: {
      MuiLinearProgress: {
        barColorPrimary: {
          backgroundColor: "rgba(50, 160, 95, 1)",
        },
        determinate: {
          backgroundColor: "#ffffff",
        },
      },
    },
  })
);

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

// Style

function Social(props) {
  return (
    <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
      <g filter="url(#filter0_d_7575_15185)">
        <path
          d="M8 28C8 16.9543 16.9543 8 28 8C39.0457 8 48 16.9543 48 28C48 39.0457 39.0457 48 28 48C16.9543 48 8 39.0457 8 28Z"
          fill="#EAD6F1"
        />
        <g clipPath="url(#clip0_7575_15185)">
          <path
            d="M36.5151 28.1892C35.8723 28.1893 35.255 28.4398 34.7938 28.8875L33.813 28.3971C34.6136 25.7716 33.1341 22.9942 30.5086 22.1936C29.5482 21.9008 28.5216 21.9057 27.5641 22.2077L26.9892 21.1698C27.9119 20.1594 27.8409 18.5923 26.8305 17.6696C25.8201 16.7469 24.253 16.8179 23.3303 17.8283C22.4075 18.8387 22.4786 20.4058 23.489 21.3285C23.9473 21.7471 24.5461 21.9784 25.1668 21.9766C25.286 21.9732 25.4047 21.9613 25.5222 21.941L26.0921 22.9706C23.9335 24.5724 23.4491 27.6048 25.0011 29.7994L22.5873 32.1187C21.3068 31.5453 19.8038 32.1184 19.2303 33.399C18.6569 34.6795 19.23 36.1825 20.5106 36.756C21.7911 37.3294 23.2941 36.7563 23.8676 35.4757C24.1871 34.7623 24.1603 33.9414 23.7951 33.2502L26.1608 30.9764C28.3714 32.5775 31.4612 32.0848 33.0642 29.8756L34.0582 30.3726C34.0425 30.4724 34.0332 30.5731 34.0301 30.6741C34.0301 32.0465 35.1427 33.1591 36.5151 33.1591C37.8876 33.1591 39.0001 32.0467 39.0001 30.6743C39.0001 29.3018 37.8875 28.1892 36.5151 28.1892Z"
            fill="#374151"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7575_15185"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2 0 0 0 0 0.454902 0 0 0 0 0.72549 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7575_15185"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7575_15185"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_7575_15185">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(19 17)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

function Business(props) {
  return (
    <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
      <g filter="url(#filter0_d_7575_15273)">
        <path
          d="M8 28C8 16.9543 16.9543 8 28 8C39.0457 8 48 16.9543 48 28C48 39.0457 39.0457 48 28 48C16.9543 48 8 39.0457 8 28Z"
          fill="#F1D6D6"
        />
        <g clipPath="url(#clip0_7575_15273)">
          <path
            d="M35.6667 25.75C35.1049 25.7695 34.5465 25.8457 34 25.9775C33.9501 24.906 33.4893 23.8948 32.7134 23.1542C31.9375 22.4135 30.906 22.0001 29.8333 22H29.6067C29.7386 21.4536 29.8146 20.8951 29.8333 20.3333C29.8333 19.4493 29.4821 18.6014 28.857 17.9763C28.2319 17.3512 27.3841 17 26.5 17C25.6159 17 24.7681 17.3512 24.143 17.9763C23.5179 18.6014 23.1667 19.4493 23.1667 20.3333C23.1854 20.8951 23.2614 21.4536 23.3933 22H23.1667C22.0616 22 21.0018 22.439 20.2204 23.2204C19.439 24.0018 19 25.0616 19 26.1667V32.8333C19 33.3805 19.1078 33.9223 19.3172 34.4278C19.5266 34.9334 19.8335 35.3927 20.2204 35.7796C21.0018 36.561 22.0616 37 23.1667 37H24.1867C24.2858 37.0004 24.3837 36.9779 24.4727 36.9342C24.5617 36.8906 24.6394 36.8269 24.6997 36.7483C24.7601 36.6696 24.8014 36.5781 24.8206 36.4808C24.8397 36.3835 24.8361 36.2831 24.81 36.1875C24.5192 35.1383 24 34.5 24 33.6667C24 33.0036 24.2634 32.3677 24.7322 31.8989C25.2011 31.4301 25.837 31.1667 26.5 31.1667C27.163 31.1667 27.7989 31.4301 28.2678 31.8989C28.7366 32.3677 29 33.0036 29 33.6667C29 34.5 28.4733 35.14 28.1892 36.1908C28.1637 36.2863 28.1606 36.3863 28.18 36.4831C28.1994 36.58 28.2408 36.671 28.3011 36.7493C28.3614 36.8275 28.4389 36.8908 28.5275 36.9343C28.6162 36.9778 28.7137 37.0003 28.8125 37H29.8333C30.3805 37 30.9223 36.8922 31.4278 36.6828C31.9334 36.4734 32.3927 36.1665 32.7796 35.7796C33.1665 35.3927 33.4734 34.9334 33.6828 34.4278C33.8922 33.9223 34 33.3805 34 32.8333V32.19C34.5465 32.3215 35.1049 32.3974 35.6667 32.4167C36.5507 32.4167 37.3986 32.0655 38.0237 31.4404C38.6488 30.8152 39 29.9674 39 29.0833C39 28.1993 38.6488 27.3514 38.0237 26.7263C37.3986 26.1012 36.5507 25.75 35.6667 25.75Z"
            fill="#374151"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7575_15273"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2 0 0 0 0 0.454902 0 0 0 0 0.72549 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7575_15273"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7575_15273"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_7575_15273">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(19 17)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

function Human(props) {
  return (
    <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
      <g filter="url(#filter0_d_7575_5396)">
        <path
          d="M8 28C8 16.9543 16.9543 8 28 8C39.0457 8 48 16.9543 48 28C48 39.0457 39.0457 48 28 48C16.9543 48 8 39.0457 8 28Z"
          fill="#F6F3DA"
        />
        <g clipPath="url(#clip0_7575_5396)">
          <path
            d="M24.25 28.8333C23.5083 28.8333 22.7833 28.6134 22.1666 28.2013C21.5499 27.7893 21.0693 27.2036 20.7855 26.5184C20.5016 25.8332 20.4274 25.0792 20.5721 24.3517C20.7167 23.6243 21.0739 22.9561 21.5983 22.4317C22.1228 21.9072 22.791 21.5501 23.5184 21.4054C24.2458 21.2607 24.9998 21.335 25.6851 21.6188C26.3703 21.9026 26.956 22.3833 27.368 22.9999C27.7801 23.6166 28 24.3417 28 25.0833C27.9989 26.0776 27.6035 27.0307 26.9004 27.7338C26.1974 28.4368 25.2442 28.8322 24.25 28.8333ZM29.6667 38H18.8333C18.6123 38 18.4004 37.9122 18.2441 37.7559C18.0878 37.5996 18 37.3877 18 37.1667V36.75C18 35.0924 18.6585 33.5027 19.8306 32.3306C21.0027 31.1585 22.5924 30.5 24.25 30.5C25.9076 30.5 27.4973 31.1585 28.6694 32.3306C29.8415 33.5027 30.5 35.0924 30.5 36.75V37.1667C30.5 37.3877 30.4122 37.5996 30.2559 37.7559C30.0996 37.9122 29.8877 38 29.6667 38ZM32.5833 25.5C31.8417 25.5 31.1166 25.2801 30.4999 24.868C29.8833 24.456 29.4026 23.8703 29.1188 23.1851C28.835 22.4998 28.7607 21.7458 28.9054 21.0184C29.0501 20.291 29.4072 19.6228 29.9317 19.0984C30.4561 18.5739 31.1243 18.2168 31.8517 18.0721C32.5792 17.9274 33.3332 18.0016 34.0184 18.2855C34.7036 18.5693 35.2893 19.0499 35.7013 19.6666C36.1134 20.2833 36.3333 21.0083 36.3333 21.75C36.3322 22.7442 35.9368 23.6974 35.2338 24.4004C34.5307 25.1035 33.5776 25.4989 32.5833 25.5ZM31.3992 27.1842C30.623 27.2883 29.8767 27.5516 29.2071 27.9575C28.5374 28.3635 27.9588 28.9034 27.5075 29.5433C29.3749 30.3914 30.8422 31.9285 31.6025 33.8333H37.1667C37.3877 33.8333 37.5996 33.7455 37.7559 33.5893C37.9122 33.433 38 33.221 38 33V32.9683C37.9991 32.1377 37.8211 31.3167 37.4777 30.5604C37.1344 29.804 36.6336 29.1296 36.0088 28.5821C35.3841 28.0347 34.6498 27.6268 33.8549 27.3857C33.0599 27.1447 32.2227 27.0759 31.3992 27.1842Z"
            fill="#374151"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7575_5396"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.827451 0 0 0 0 0.772549 0 0 0 0 0.282353 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7575_5396"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7575_5396"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_7575_5396">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(18 18)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

function Environmental(props) {
  return (
    <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
      <g filter="url(#filter0_d_7369_20358)">
        <path
          d="M8 28C8 16.9543 16.9543 8 28 8C39.0457 8 48 16.9543 48 28C48 39.0457 39.0457 48 28 48C16.9543 48 8 39.0457 8 28Z"
          fill="#DAF1E4"
        />
        <g clipPath="url(#clip0_7369_20358)">
          <path
            d="M37.2659 18.7265C37.0055 18.4666 36.6906 18.2678 36.344 18.1443C35.9975 18.0208 35.6278 17.9757 35.2618 18.0123C32.9343 18.2373 25.1067 19.2065 22.0834 22.2265C20.6382 23.6756 19.7812 25.6088 19.678 27.6528C19.5747 29.6968 20.2325 31.7066 21.5242 33.294L30.7409 24.0773C30.8981 23.9255 31.1086 23.8415 31.3271 23.8434C31.5456 23.8453 31.7546 23.933 31.9091 24.0875C32.0636 24.242 32.1512 24.451 32.1531 24.6695C32.155 24.888 32.071 25.0985 31.9192 25.2557L22.7059 34.4723C24.2928 35.7649 26.3024 36.4235 28.3465 36.3208C30.3905 36.2181 32.324 35.3615 33.7734 33.9165C36.8368 30.8523 37.7734 23.049 37.9893 20.7307C38.0245 20.3639 37.978 19.9939 37.8529 19.6473C37.7278 19.3007 37.5273 18.9862 37.2659 18.7265ZM22.0834 33.9165C22.2823 34.1148 22.4887 34.3012 22.7026 34.4757L19.4192 37.759C19.2617 37.9104 19.0509 37.9939 18.8324 37.9914C18.6139 37.9889 18.4051 37.9006 18.251 37.7457C18.0969 37.5907 18.0098 37.3814 18.0086 37.1629C18.0073 36.9443 18.0919 36.734 18.2442 36.5773L21.5276 33.294C21.702 33.5073 21.8873 33.7148 22.0834 33.9165Z"
            fill="#374151"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7369_20358"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.278431 0 0 0 0 0.733333 0 0 0 0 0.462745 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7369_20358"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7369_20358"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_7369_20358">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(18 18)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

function Governance(props) {
  return (
    <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
      <g filter="url(#filter0_d_7369_26383)">
        <path
          d="M8 28C8 16.9543 16.9543 8 28 8C39.0457 8 48 16.9543 48 28C48 39.0457 39.0457 48 28 48C16.9543 48 8 39.0457 8 28Z"
          fill="#D6E3F1"
        />
        <g clipPath="url(#clip0_7369_26383)">
          <path
            d="M18.2424 25.126C18.0628 24.7929 17.9796 24.4164 18.0021 24.0387C18.0247 23.661 18.152 23.297 18.3699 22.9876C18.7259 22.4652 19.2027 22.0364 19.7599 21.7376L26.0107 18.4843C26.6251 18.1648 27.3074 17.998 27.9999 17.998C28.6924 17.998 29.3746 18.1648 29.989 18.4843L36.239 21.7401C36.7962 22.0389 37.273 22.4677 37.629 22.9901C37.8469 23.2995 37.9742 23.6635 37.9968 24.0412C38.0193 24.4189 37.9361 24.7954 37.7565 25.1285C37.5632 25.4942 37.2733 25.7998 36.9183 26.0121C36.5633 26.2245 36.1568 26.3353 35.7432 26.3326H20.2565C19.8425 26.3352 19.4357 26.2241 19.0805 26.0113C18.7254 25.7985 18.4354 25.4923 18.2424 25.126ZM37.1665 36.3326C37.1665 35.8906 36.9909 35.4667 36.6784 35.1541C36.3658 34.8416 35.9419 34.666 35.4999 34.666V27.9993H33.8332V34.666H31.3332V27.9993H29.6665V34.666H26.3332V27.9993H24.6665V34.666H22.1665V27.9993H20.4999V34.666C20.0578 34.666 19.6339 34.8416 19.3214 35.1541C19.0088 35.4667 18.8332 35.8906 18.8332 36.3326C18.6122 36.3326 18.4002 36.4204 18.244 36.5767C18.0877 36.733 17.9999 36.945 17.9999 37.166C17.9999 37.387 18.0877 37.5989 18.244 37.7552C18.4002 37.9115 18.6122 37.9993 18.8332 37.9993H37.1665C37.3875 37.9993 37.5995 37.9115 37.7558 37.7552C37.9121 37.5989 37.9999 37.387 37.9999 37.166C37.9999 36.945 37.9121 36.733 37.7558 36.5767C37.5995 36.4204 37.3875 36.3326 37.1665 36.3326Z"
            fill="#374151"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7369_26383"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2 0 0 0 0 0.454902 0 0 0 0 0.72549 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7369_26383"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7369_26383"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_7369_26383">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(18 18)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

// Card Details
const cardData = [
  {
    id: 1,
    img: BCD,
    title: "Basic Company Details",
  },
  {
    id: 2,
    img: MA,
    title: "Materiality Information",
  },
  {
    id: 3,
    img: SRG,
    title: "Get Started",
  },
];

function Onboaring(props) {
  const {
    login,
    addOrganisationUnit,
    setLogin,
    updateOrganisationUnit,
    setSideImage,
    setSideMessage,
    setIsNewCompany,
    setCompanyName,
    getSectorData,
    getTopics,
    viewFrameworks,
    getAllCountries,
    basicInfo,
    viewFilteredFrameworks,
    getSectorsByFramework,
    materialityAssessment,
  } = props;
  // States

  const classes = onBoardingUseStyles();
  const navigate = useNavigate();

  const [progress, setProgress] = React.useState(33.333);
  const [redirect, setRedirect] = React.useState(false);
  const [hideCards, setHideCards] = React.useState(false);
  const [buildingComp, setBuildingComp] = React.useState(true);
  const [factoryComp, setFactoryComp] = React.useState(false);
  const [countryComp, setCountryComp] = React.useState(false);
  const [networkComp, setNetworkComp] = React.useState(false);
  const [questionNumber, setQuestionNumber] = React.useState(1);

  const [show, setShow] = React.useState(false);
  const [show2, setShow2] = React.useState(false);
  const [show3] = React.useState(false);

  const [framework, setFramework] = React.useState(true);
  const [frameworkId, setFrameworkId] = React.useState("");
  const [topic, setTopic] = React.useState(false);

  const [companyNameLocal, setCompanyNameLocal] = React.useState("");

  const [employeeCount, setEmployeeCount] = React.useState("");
  const [countryLocal, setCountryLocal] = React.useState("");

  const [sectors, setSectors] = useState([]);
  const [selectedSectorData, setSelectedSectorData] = useState([]);
  const [displaySectorData, setdDsplaySectorData] = useState([]);
  const [industryLength, setIndustryLength] = useState(0);

  const [filteredSearch, setFilteredSearch] = useState([]);
  const [searchText, setSearchText] = useState("");
  // Card State Handle
  const [activeCardId, setActiveCardId] = React.useState(1);
  const [completedCardId, setCompletedCardId] = React.useState([]);
  const [employeeCountArray] = React.useState([
    "0-50",
    "51-250",
    "251-1000",
    "1000-5000",
    "5000+",
  ]);

  const [expanded, setExpanded] = React.useState(false);

  const [checked, setChecked] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleToggle = (value, id) => () => {
    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSelectall = () => {
    if (checked.length < login?.topicsData?.topics_data?.length) {
      login?.topicsData?.topics_data?.map((topic) => {
        return (
          !checked.includes(topic._id) &&
          setChecked((prev) => [...prev, topic._id])
        );
      });
    } else {
      setChecked([]);
    }
  };

  const FrameworksDialogs = React.useMemo(
    () => (
      <FrameworksDialog
        // setMetricLis={setMetricLis}
        open={framework}
        setOpen={setFramework}
        framework={basicInfo.filteredFrameworks}
        template={basicInfo.templateType}
        onClose={(id, template, defaultFilter) => handleFramework(id)}
      />
    ),
    [framework, basicInfo.filteredFrameworks] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (login.signupProcessNo === 3) {
      setCompletedCardId([1]);
    }
    if (login.signupProcessNo === 1) {
      setSideMessage("otp");
    } else if (login.signupProcessNo === 4) {
      return <Navigate to="/get_started" />;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeCardId === 1) {
      setSideMessage("firstStep");
      setSideImage("build");
    } else if (activeCardId === 2) {
      setSideMessage("secondStep");
      setSideImage("cert");
    }
  }, [activeCardId]);

  React.useEffect(() => {
    if (!hideCards) {
      setTimeout(() => {
        setHideCards(true);
      }, 800);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAllCountries(login.token || localStorage.getItem("user_token"));
    // getSectorData(login.token);
    // viewFrameworks(login.token);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   getTopics(
  //     login.token,
  //     localStorage.getItem("current_organisation") !== null
  //       ? localStorage.getItem("current_organisation")
  //       : login.organisation_id[0]
  //   );
  // }, [login.organisation_id[0], localStorage.getItem("current_organisation")]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSectors(basicInfo.masterSectors);
  }, [basicInfo.masterSectors]);
  const handleCardClick = (id) => {
    if (id === 1) {
      setHideCards(false);
      setShow(true);
      setSideMessage("firstStep");
      setSideImage("build");
    } else if (id === 2) {
      setHideCards(false);
      setShow2(true);
      basicInfo.allCountries.length > 0 &&
        basicInfo.allCountries.map((countries) => {
          if (countries.country_code === localStorage.getItem("country_code")) {
            viewFilteredFrameworks(
              login.token,
              countries._id,
              login.current_organisation
            );
          }
        });
    } else if (id === 3) {
      setRedirect(true);

      setHideCards(false);
      setShow2(false);
      setIsNewCompany(false);
      setLogin({
        user_token: localStorage.getItem("user_token"),
        user_type: localStorage.getItem("current_organisation_user_type"),
        userName: localStorage.getItem("userName"),
        userEmail: localStorage.getItem("userEmail"),
        name: localStorage.getItem("userName"),
        email: localStorage.getItem("userEmail"),
        parent_organisation: JSON.parse(
          localStorage.getItem("parent_organisation")
        ),
        current_organisation: localStorage.getItem("current_organisation"),
        isLoggedIn: true,
        signup_process: 4,
      });
    }
  };

  useEffect(() => {
    if (login.signupProcessNo === 3) {
      setCompletedCardId([1]);
      setActiveCardId(2);
    }
    if (
      login.signupProcessNo === 4 &&
      login.tutorialCompletedSteps.length > 0
    ) {
      return navigate("/survey_listing");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBackStepOne = () => {
    setHideCards(true);
    setShow(false);
  };
  const handleEmployeBack = () => {
    setNetworkComp(false);
    setCountryComp(true);
    setQuestionNumber(questionNumber - 1);
    setProgress(progress - 33.33);
  };
  const handleCompanyBack = () => {
    setHideCards(true);
    setShow(false);
  };
  const handleCountryBack = () => {
    setBuildingComp(true);
    setCountryComp(false);
    setQuestionNumber(questionNumber - 1);
    setProgress(progress - 33.33);
  };

  const handleStepOne = () => {
    setBuildingComp(false);
    setQuestionNumber(questionNumber + 1);
    setProgress(progress + 33.33);
    setTimeout(() => {
      setCountryComp(true);
    }, 200);
    setCompanyName(companyNameLocal);
  };
  const handleStepTwo = () => {
    // industry sector
    setCountryComp(false);
    setQuestionNumber(questionNumber + 1);
    setProgress(progress + 33.33);
    setTimeout(() => {
      // setFactoryComp(true);
      setNetworkComp(true);
    }, 200);
  };

  const handleStepThree = () => {
    setFactoryComp(false);
    setQuestionNumber(questionNumber + 1);
    setProgress(75);
    setNetworkComp(true);
    // setBuildingComp(true);
    setSideMessage("secondStep");
    setSideImage("cert");
    // handleBackStepOne();

    // setCompletedCardId([1]);
    // setActiveCardId(2);
    // addOrganisationUnit(
    //   login.token,
    //   companyNameLocal,
    //   selectedSectorData,
    //   employeeCount
    // );
  };
  const handleStepFour = () => {
    setFactoryComp(false);
    setQuestionNumber(1);
    setProgress(25);
    setNetworkComp(false);
    setBuildingComp(true);
    setSideMessage("secondStep");
    setSideImage("cert");
    handleBackStepOne();

    setCompletedCardId([1]);
    setActiveCardId(2);
    addOrganisationUnit(
      login.token,
      companyNameLocal,
      countryLocal,
      employeeCount
    );
  };

  // const handleStepOne = () => {
  //   setBuildingComp(false);
  //   setQuestionNumber(questionNumber + 1);
  //   setProgress(progress + 33.334);
  //   setTimeout(() => {
  //     setFactoryComp(true);
  //   }, 200);
  //   setCompanyName(companyNameLocal);
  // };
  // const handleStepTwo = () => {
  //   // industry sector
  //   setFactoryComp(false);
  //   setQuestionNumber(questionNumber + 1);
  //   setProgress(progress + 33.334);
  //   setTimeout(() => {
  //     setNetworkComp(true);
  //   }, 200);
  // };
  // const handleStepThree = () => {
  //   setFactoryComp(false);
  //   setCountryComp(true);
  //   setQuestionNumber(questionNumber + 1);
  //   setProgress(33.334);
  //   setNetworkComp(false);
  //   // setBuildingComp(true);
  //   setSideMessage("secondStep");
  //   setSideImage("cert");
  //   // handleBackStepOne();

  //   // setCompletedCardId([1]);
  //   // setActiveCardId(2);
  //   // addOrganisationUnit(
  //   //   login.token,
  //   //   companyNameLocal,
  //   //   selectedSectorData,
  //   //   employeeCount
  //   // );
  // };
  // const handleStepFour = () => {
  //   setFactoryComp(false);
  //   setQuestionNumber(1);
  //   setProgress(33.334);
  //   setNetworkComp(false);
  //   setBuildingComp(true);
  //   setSideMessage("secondStep");
  //   setSideImage("cert");
  //   handleBackStepOne();

  //   setCompletedCardId([1]);
  //   setActiveCardId(2);
  //   addOrganisationUnit(
  //     login.token,
  //     companyNameLocal,
  //     selectedSectorData,
  //     employeeCount
  //   );
  // };
  const handleFramework = (id) => {
    setFramework(false);
    setFactoryComp(true);
    getSectorsByFramework(login.token, id);
    setFrameworkId(id);
    // setTopic(true);
    // setHideCards(true);
    // setSideMessage("thirdStep");

    // setShow2(false);
    // setCompletedCardId([1, 2]);
    // setActiveCardId(3);
    // updateOrganisationUnit(
    //   login.token,
    //   localStorage.getItem("current_organisation") !== null
    //     ? localStorage.getItem("current_organisation")
    //     : login.organisation_id[0],
    //   selectedIndex,
    //   checked
    // );
    // localStorage.setItem("user_token", login.token);
  };
  const handleBackFramework = () => {
    setHideCards(true);
    setShow2(false);
    // setTopic(true);
    // setFramework(false);
  };
  const handleTopic = () => {
    setTopic(false);
    setHideCards(true);
    setSideMessage("thirdStep");

    setShow2(false);
    setCompletedCardId([1, 2]);
    setActiveCardId(3);
    updateOrganisationUnit(
      login.token,
      localStorage.getItem("current_organisation") !== null
        ? localStorage.getItem("current_organisation")
        : login.organisation_id[0],
      frameworkId,
      checked,
      selectedSectorData
    );
    login.token && localStorage.setItem("user_token", login.token);
  };

  const handleBackTopic = () => {
    // setHideCards(true);
    // setShow2(false);
    setTopic(true);
    setFramework(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleIndustryChange = (industryIndex, sectorIndex, clickedSector) => {
    // console.log("index", industryIndex, sectorIndex);
    // console.log("sectors", sectors);

    const filteredSector = sectors.map((sector, ind) => {
      const isSector = sector.Sector === clickedSector;
      // console.log("isSector",isSector);

      if (isSector) {
        // console.log("sectorL",industryIndex);
        // var sectorL = [...sectors];
        sector.industry_data[industryIndex].isSelected =
          !sector.industry_data[industryIndex].isSelected;
        // setSectors([...new Set(sectorL)]);
        // console.log("sec",sector);
      }
      return null;
    });
    // console.log("setSelectedSectorData", filteredSector);
    const selectedSector = sectors
      .map((sec) => {
        const industries = sec.industry_data
          .filter((ind) => ind.isSelected)
          .map((ind2) => ind2._id);
        if (industries.length) {
          return { sector: sec._id, industry: industries };
        }
        return null;
      })
      .filter((s) => {
        return s;
      });
    // console.log("setSelectedSectorData", selectedSector);
    setSelectedSectorData(selectedSector);
  };
  // const handleIndustryChange = (industryIndex, sectorIndex) => {
  //   console.log("index", industryIndex, sectorIndex);
  //   console.log("sectors", sectors);
  //   var sectorL = [...sectors,...filteredSearch];
  //   sectorL[sectorIndex].industry_data[industryIndex].isSelected =
  //     !sectorL[sectorIndex].industry_data[industryIndex].isSelected;
  //   setSectors( [...new Set(sectorL)]);
  //   console.log("sectorL", sectorL);

  //   const filteredSector = sectors
  //     .map((sec) => {
  //       const industries = sec.industry_data
  //         .filter((ind) => ind.isSelected)
  //         .map((ind2) => ind2._id);
  //       if (industries.length) {
  //         return { sector: sec._id, industry: industries };
  //       }
  //       return null;
  //     })
  //     .filter((s) => {
  //     return s;
  //     });
  //   // setSelectedSectorData(filteredSector);
  //   console.log("setSelectedSectorData", filteredSector);
  // };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const generateAccordian = (accordionData) => {
    return accordionData.map((s, index) => (
      <Accordion
        expanded={expanded === `panel1${index}`}
        onChange={handleChange(`panel1${index}`)}
        elevation={0}
        classes={{
          root: classes.accordion,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          classes={{
            content: classes.accordionSummary,
          }}
        >
          <Typography
            name={s.Sector.substring(0, 4)}
            className={classes.accordionHeading}
          >
            {s.Sector}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          classes={{
            root: classes.accordionDetails,
          }}
        >
          {s.industry_data.map((industry, index2) => (
            <div
              className={classes.mainDiv}
              style={{
                background: industry.isSelected
                  ? "rgba(214, 227, 241, 0.45)"
                  : "none",
              }}
            >
              <CustomCheckBox
                name={industry?.Industry?.substring(0, 4)}
                checked={industry.isSelected ? true : false}
                onChange={() => handleIndustryChange(index2, index, s.Sector)}
              />
              <Typography name={industry?.Industry?.substring(0, 4)}>
                {industry.Industry}
              </Typography>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    ));
  };

  useEffect(() => {
    if (selectedSectorData.length) {
      const indArray = selectedSectorData.map((sec) => sec.industry);
      setIndustryLength([].concat(...indArray).length);
    }
    if (selectedSectorData.length === 0) {
      setIndustryLength(0);
    }
  }, [selectedSectorData]);

  useEffect(() => {
    let filtered = [];
    if (searchText.length) {
      filtered = basicInfo.masterSectors.filter((comp) => {
        let names = comp.Sector.toLowerCase();
        return names.includes(searchText.toLowerCase());
      });
    }
    console.log("filtered", filtered);
    setFilteredSearch(filtered);
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  function MyCards() {
    return (
      <>
        <Slide
          direction="up"
          in={hideCards}
          timeout={800}
          mountOnEnter
          unmountOnExit
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            {cardData.map((card) => {
              const { id, img, title } = card;
              return (
                <Grid
                  key={id}
                  container
                  item
                  xs={12}
                  className={`${
                    completedCardId.some((cid) => cid === id)
                      ? classes.cardCompleted
                      : id === activeCardId
                        ? classes.activeCard
                        : classes.myCard
                  }`}
                  onClick={() => {
                    id === activeCardId && handleCardClick(id);
                  }}
                  style={{ maxHeight: 160 }}
                >
                  {completedCardId.some((cid) => cid === id) ? (
                    <img src={tick} alt="tick" className={classes.tickImg} />
                  ) : null}
                  <Grid item xs={4}>
                    <img className={classes.cardImg} src={img} alt="img" />
                  </Grid>
                  <Grid container item xs={8} alignItems="center">
                    <Grid item sm={12}>
                      <Typography variant="h6">{title}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Slide>
      </>
    );
  }

  const MyCard = useMemo(() => MyCards, [hideCards]); // eslint-disable-line react-hooks/exhaustive-deps
  function MyButtons(props) {
    return (
      <>
        <Grid style={{ paddingBottom: "16px" }} item xs={3} md={2}>
          <CustomButton
            name="backBtn"
            variant="outlined"
            color="primary"
            onClick={() => {
              setSearchText("");
              props.back();
            }}
          >
            Back
          </CustomButton>
        </Grid>
        <Grid style={{ paddingBottom: "16px" }} item xs={4} md={2}>
          <CustomButton
            name="NextBtn"
            variant="contained"
            color="primary"
            disabled={props.disabled}
            onClick={() => {
              setSearchText("");
              props.next();
            }}
          >
            Next
          </CustomButton>
        </Grid>
      </>
    );
  }

  const handleOnselect = (code) => {
    basicInfo.allCountries.length > 0 &&
      basicInfo.allCountries.map((countries) => {
        if (countries.country_name === code) {
          localStorage.setItem("country_code", countries.country_code);
          viewFilteredFrameworks(
            login.token,
            countries._id,
            login.current_organisation
          );
        }
      });

    // setReportingRegion(code);
  };

  if (redirect) {
    return null;
  } else
    return (
      <>
        <Hidden mdDown>
          <div className={classes.hiddenDiv}>
            <img
              src={
                localStorage.getItem("logo")
                  ? localStorage.getItem("logo")
                  : logoWhite
              }
              alt="logo"
              style={
                JSON.parse(localStorage.getItem("logo_styles"))
                  ? JSON.parse(localStorage.getItem("logo_styles"))
                  : login.company_logo
                    ? login.company_logo.logo_tyles
                    : {}
              }
            />
          </div>
          <Grid item container className={classes.hiddenGrid}>
            <Grid
              container
              item
              className={`blueDiv ${true ? "blueDivAnimation" : ""}`}
              style={{ zIndex: "2" }}
            >
              {true && (
                <Grid item container className={classes.hiddenGrid1}>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    className={classes.hiddenGrid2}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      paddingLeft="64px"
                      maxWidth="270px"
                    >
                      <img src={userAvatar} alt="user" className="avatar" />

                      {login.sideMessage === "onboarding" && (
                        <>
                          <Typography className={classes.sideBarTitle}>
                            Onboarding
                          </Typography>
                          <Typography className={classes.sideBarSubTitle}>
                            On the right you have the Section Split to get a
                            better output, start from Basic company details
                          </Typography>
                        </>
                      )}
                      {login.sideMessage === "firstStep" && (
                        <>
                          <Typography className={classes.sideBarTitle}>
                            Basic Company details
                          </Typography>
                          <Typography className={classes.sideBarSubTitle}>
                            These details help you fill out the Reportings much
                            more faster
                          </Typography>
                        </>
                      )}
                      {login.sideMessage === "secondStep" && (
                        <>
                          <Typography className={classes.sideBarTitle}>
                            Materiality assesment
                          </Typography>
                          <Typography className={classes.sideBarSubTitle}>
                            This involves frameworks required to make your
                            reports
                          </Typography>
                        </>
                      )}
                      {login.sideMessage === "thirdStep" && (
                        <>
                          <Typography className={classes.sideBarTitle}>
                            Alright, Lets get started
                          </Typography>
                          <Typography className={classes.sideBarSubTitle}>
                            This involves frameworks required to make your
                            reports
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Grid>

                  <Grid
                    sx={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    item
                    className={classes.grid1}
                    justifyContent="center"
                  >
                    <Slide
                      direction="right"
                      in={props.login.sideImage === "build"}
                      timeout={500}
                      mountOnEnter
                      unmountOnExit
                    >
                      <img src={build} alt="build" />
                    </Slide>
                    <Slide
                      direction="right"
                      in={props.login.sideImage === "cert"}
                      timeout={500}
                      mountOnEnter
                      unmountOnExit
                    >
                      <img src={cert} alt="build" />
                    </Slide>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.grid2}
                  >
                    <div className={classes.grid2}>
                      <ul className={classes.ul}>
                        <li className="footerList">
                          <a
                            className={classes.link}
                            href="/faq"
                            target="_blank"
                          >
                            FAQ
                          </a>
                        </li>
                        <li className="footerList">
                          <a
                            className={classes.link}
                            href="/tnc"
                            target="_blank"
                          >
                            Terms & Conditions{" "}
                          </a>
                        </li>
                        <li className="footerList">
                          <a
                            className={classes.link}
                            href="/privacy_policy"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              )}
              {/* true close */}
            </Grid>

            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={9}
            >
              <Grid item xs={7}>
                {
                  <div className={classes.root}>
                    {hideCards && <MyCard />}
                    {/* Basic Company Details */}
                    {show && (
                      <>
                        <Slide
                          direction="up"
                          in={show}
                          timeout={500}
                          mountOnEnter
                          unmountOnExit
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            bgcolor="#FFFFFF"
                            borderRadius="10px"
                            width="850px"
                            height="623px"
                          >
                            <Grid
                              item
                              container
                              xs={12}
                              className={classes.grid3}
                            >
                              <Grid item xs={11} className={classes.title}>
                                <Typography className={classes.typo1}>
                                  Question {questionNumber} of 3
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={classes.progressBar}
                              >
                                <StyledEngineProvider injectFirst>
                                  <ThemeProvider theme={myProgressBar}>
                                    <LinearProgress
                                      variant="determinate"
                                      color="primary"
                                      value={progress}
                                    />
                                  </ThemeProvider>
                                </StyledEngineProvider>
                              </Grid>
                            </Grid>
                            {buildingComp && (
                              <Box className={classes.textInput}>
                                <CustomTextfield
                                  name="compName"
                                  variant="outlined"
                                  size="small"
                                  label="Company Name"
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.label,
                                    },
                                  }}
                                  className={classes.textField}
                                  value={companyNameLocal}
                                  onChange={(event) => {
                                    setCompanyNameLocal(event.target.value);
                                  }}
                                ></CustomTextfield>
                              </Box>
                            )}
                            {countryComp && (
                              <Box className={classes.textInput}>
                                <Autocomplete
                                  style={{ width: "80%" }}
                                  name="compCountry"
                                  id="countryCode"
                                  options={basicInfo?.allCountries.map(
                                    (cont) => cont.country_name
                                  )}
                                  getOptionLabel={(option) => {
                                    // console.log(option)
                                    return option;
                                  }}
                                  renderOption={(option) => option}
                                  size="small"
                                  renderInput={(params) => (
                                    <CustomTextfield
                                      {...params}
                                      InputLabelProps={{
                                        classes: {
                                          root: classes.label,
                                        },
                                      }}
                                      label="Type / Select Country"
                                      variant="outlined"
                                    />
                                  )}
                                  value={countryLocal || ""}
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      console.log("newValue", newValue);
                                      setCountryLocal(newValue);
                                      handleOnselect(newValue);
                                    }
                                  }}
                                />
                                {/* <ReactFlagsSelect
                                selected={basicInfo.reportingRegion}
                                onSelect={(code) => handleOnselect(code)}
                                searchable
                                optionsSize={18}
                                selectedSize={18}
                              /> */}
                              </Box>
                            )}

                            {networkComp && (
                              <Box
                                paddingBottom="188px"
                                paddingTop="192px"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                              >
                                <CustomTextfield
                                  name="employeeCtn"
                                  variant="outlined"
                                  size="small"
                                  label="Employee Count"
                                  className={classes.textField}
                                  InputLabelProps={{
                                    classes: {
                                      root: classes.label,
                                    },
                                  }}
                                  value={employeeCount}
                                  onChange={(event) => {
                                    setEmployeeCount(event.target.value);
                                  }}
                                ></CustomTextfield>
                                <Box display="flex" paddingTop="64px">
                                  <StyledEngineProvider injectFirst>
                                    <ThemeProvider theme={myChip}>
                                      {employeeCountArray.map(
                                        (count, index) => {
                                          return (
                                            <Grid item>
                                              <div
                                                key={index}
                                                className={classes.chip}
                                              >
                                                <Chip
                                                  clickable="true"
                                                  variant="outlined"
                                                  color="secondary"
                                                  label={count}
                                                  className={classes.chip1}
                                                  icon={
                                                    employeeCount === count && (
                                                      <CheckIcon />
                                                    )
                                                  }
                                                  onClick={() => {
                                                    setEmployeeCount(count);
                                                  }}
                                                />
                                              </div>
                                            </Grid>
                                          );
                                        }
                                      )}
                                    </ThemeProvider>
                                  </StyledEngineProvider>
                                </Box>
                              </Box>
                            )}
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="flex-end"
                              backgroundColor="#FFFFF"
                            >
                              {!hideCards && buildingComp ? (
                                <MyButtons
                                  back={handleCompanyBack}
                                  disabled={companyNameLocal === ""}
                                  next={
                                    companyNameLocal === "" ? "" : handleStepOne
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {!hideCards && countryComp ? (
                                <MyButtons
                                  back={handleCountryBack}
                                  disabled={countryLocal === ""}
                                  next={countryComp === "" ? "" : handleStepTwo}
                                />
                              ) : (
                                ""
                              )}
                              {/* {!hideCards && factoryComp ? (
                              <MyButtons
                                back={handleBackStepTwo}
                                next={handleStepThree}
                                disabled={selectedSectorData.length === 0}
                              />
                            ) : (
                              ""
                            )} */}
                              {!hideCards && networkComp ? (
                                <MyButtons
                                  back={handleEmployeBack}
                                  disabled={employeeCount === ""}
                                  next={
                                    employeeCount === "" ? "" : handleStepFour
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </Box>
                          </Box>
                        </Slide>
                      </>
                    )}

                    {/* Materiality Assesments */}
                    {show2 && (
                      <Slide
                        direction="up"
                        in={show2}
                        timeout={500}
                        mountOnEnter
                        unmountOnExit
                      >
                        <div
                          style={{ width: "850px", backgroundColor: "#FFFF" }}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            bgcolor="#FFFFFF"
                            borderRadius="10px"
                            width="850px"
                            height={framework ? "630px" : "580px"}
                            overflow="scroll"
                          >
                            {/* Framework Section */}
                            {framework && (
                              <Slide
                                direction="up"
                                timeout={500}
                                in={framework}
                              >
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  justifyContent="center"
                                  className={classes.cardContent}
                                >
                                  <Grid item xs={12}>
                                    <Typography
                                      className={classes.frameworkTitle}
                                    >
                                      Reporting Framework Selection
                                    </Typography>
                                  </Grid>
                                  {Object.keys(
                                    basicInfo.filteredFrameworks
                                  ).map((key, index) => {
                                    // console.log('key', basicInfo.filteredFrameworks[key]);
                                    return (
                                      basicInfo.filteredFrameworks[key].length >
                                        0 && (
                                        <>
                                          <Grid
                                            container
                                            justifyContent="center"
                                          >
                                            <Grid
                                              sm={11}
                                              md={11}
                                              lg={11}
                                              className={classes.dialogContent}
                                            >
                                              <Typography
                                                variant="body1"
                                                className={
                                                  classes.dialogContentTypo
                                                }
                                              >
                                                {key === "mandatory_data"
                                                  ? "National Framework"
                                                  : key === "recommended_data"
                                                    ? "Recommended frameworks"
                                                    : "Others FrameWorks"}
                                              </Typography>
                                            </Grid>
                                          </Grid>

                                          <FrameworkContent
                                            framework={
                                              basicInfo.filteredFrameworks[key]
                                            }
                                            setOpen={props.setOpen}
                                            open={true}
                                            frameworkId={frameworkId}
                                            setFrameworkId={setFrameworkId}
                                            // onClose={(id) =>
                                            //   handleFramework(id)
                                            // }
                                          />
                                        </>
                                      )
                                    );
                                  })}
                                </Grid>
                              </Slide>
                            )}
                            {/* {FrameworksDialogs} */}
                            {factoryComp && (
                              <Box
                                display="flex"
                                paddingX="16px"
                                paddingTop="44px"
                                paddingBottom="8px"
                              >
                                <Box width="100%" marginRight="16px">
                                  <CustomTextfield
                                    name="searchSector"
                                    onChange={(e) => {
                                      setSearchText(e.target.value);
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        color: "#3374B9",
                                        opacity: "0.45",
                                        textTrasform: "capitalize",
                                      },
                                    }}
                                    label="Select your sector and industry"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    className={classes.CustomTextfield}
                                    InputProps={{
                                      classes: {
                                        notchedOutline: classes.notchedOutline,
                                      },
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <SearchIcon
                                            name="seachSectorIcon"
                                            fontSize="40px"
                                            style={{ color: "#3374B9" }}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />

                                  <Card className={classes.sectorCard}>
                                    <CardContent
                                      className={classes.cardContent}
                                    >
                                      <div className={classes.div1}>
                                        {filteredSearch.length > 0 ? (
                                          generateAccordian(filteredSearch)
                                        ) : searchText.length > 0 ? (
                                          <p>No Data Found</p>
                                        ) : (
                                          generateAccordian(
                                            basicInfo.masterSectors
                                          )
                                        )}
                                      </div>
                                    </CardContent>
                                  </Card>
                                </Box>
                                <Box width="100%">
                                  <Box
                                    height="48px"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    paddingX="16px"
                                  >
                                    <Typography
                                      className={classes.selectedText}
                                    >
                                      Selected
                                    </Typography>
                                    <Typography
                                      className={classes.selectedText}
                                    >
                                      ({selectedSectorData.length} sectors,{" "}
                                      {industryLength}
                                      {""} industries selected)
                                    </Typography>
                                  </Box>
                                  <Card
                                    variant="outlined"
                                    className={classes.card}
                                  >
                                    <CardContent>
                                      {sectors.map((sector, index) => (
                                        <div style={{ marginBottom: 8 }}>
                                          {sector.industry_data.some(
                                            (inds) => inds.isSelected
                                          ) && (
                                            <>
                                              <Typography
                                                className={
                                                  classes.selectedSectorTitle
                                                }
                                              >
                                                {sector.Sector}
                                              </Typography>
                                              {sector.industry_data.map(
                                                (industry, index2) =>
                                                  industry.isSelected && (
                                                    <Chip
                                                      className={
                                                        classes.sectorChip
                                                      }
                                                      onDelete={() =>
                                                        handleIndustryChange(
                                                          index2,
                                                          index,
                                                          sector.Sector
                                                        )
                                                      }
                                                      deleteIcon={
                                                        <RemoveCircleOutlinedIcon
                                                          name={industry.Industry.substring(
                                                            0,
                                                            4
                                                          )}
                                                          className={
                                                            classes.removeChipIcon
                                                          }
                                                        />
                                                      }
                                                      label={industry.Industry}
                                                    />
                                                  )
                                              )}
                                            </>
                                          )}
                                        </div>
                                      ))}
                                    </CardContent>
                                  </Card>
                                </Box>
                              </Box>
                            )}
                            {/* Topic Section */}

                            {topic && (
                              <Slide direction="up" timeout={500} in={topic}>
                                <Box width="100%">
                                  <Card className={classes.topicCard}>
                                    <CardContent
                                      className={classes.cardContent}
                                    >
                                      <Typography
                                        className={classes.topicTitle}
                                      >
                                        Topic Selection
                                      </Typography>
                                      <Grid container spacing={2}>
                                        {Array.isArray(
                                          login?.topicsData?.main_topics
                                        ) &&
                                          login?.topicsData?.main_topics.map(
                                            (topic) => (
                                              <Grid item xs={6} md={4}>
                                                <Box
                                                  className={
                                                    classes.topicSubContainer
                                                  }
                                                >
                                                  {(topic.topic_name ===
                                                    "Environment" ||
                                                    topic.topic_name ===
                                                      "Environmental") && (
                                                    <Environmental
                                                      fontSize="large"
                                                      classes={{
                                                        fontSizeLarge:
                                                          classes.large,
                                                      }}
                                                    />
                                                  )}
                                                  {topic.topic_name ===
                                                    "Social Capital" && (
                                                    <Social
                                                      fontSize="large"
                                                      classes={{
                                                        fontSizeLarge:
                                                          classes.large,
                                                      }}
                                                    />
                                                  )}
                                                  {topic.topic_name ===
                                                    "Leadership & Governance" && (
                                                    <Governance
                                                      fontSize="large"
                                                      classes={{
                                                        fontSizeLarge:
                                                          classes.large,
                                                      }}
                                                    />
                                                  )}
                                                  {topic.topic_name ===
                                                    "Human Capital" && (
                                                    <Human
                                                      fontSize="large"
                                                      classes={{
                                                        fontSizeLarge:
                                                          classes.large,
                                                      }}
                                                    />
                                                  )}
                                                  {topic.topic_name ===
                                                    "Business Model & Innovation" && (
                                                    <Business
                                                      fontSize="large"
                                                      classes={{
                                                        fontSizeLarge:
                                                          classes.large,
                                                      }}
                                                    />
                                                  )}

                                                  <Typography
                                                    className={
                                                      classes.topicSubtitle
                                                    }
                                                  >
                                                    {topic.topic_name}
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                            )
                                          )}

                                        <Grid item className={classes.grid3}>
                                          <CustomButton
                                            name={
                                              Array.isArray(
                                                login.topicsData?.topics_data
                                              ) &&
                                              login?.topicsData?.topics_data
                                                .length === checked.length
                                                ? "unselectAllBtn"
                                                : "selectAllBtn"
                                            }
                                            variant="contained"
                                            color="secondary"
                                            style={{
                                              textTransform: "none",
                                              fontFamily: "Poppins",
                                            }}
                                            size="small"
                                            onClick={handleSelectall}
                                          >
                                            {Array.isArray(
                                              login.topicsData?.topics_data
                                            ) &&
                                            login.topicsData?.topics_data
                                              .length === checked.length
                                              ? "Unselect All"
                                              : "Select All"}
                                          </CustomButton>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                  <Box>
                                    {console.log(materialityAssessment)}
                                    <Box className={classes.box1}>
                                      {login?.topicsData?.topics_data?.length >
                                        0 &&
                                        login.topicsData.topics_data.map(
                                          (topic, index) => {
                                            return (
                                              <List>
                                                <ListItem
                                                  name={topic?.text?.substring(
                                                    0,
                                                    4
                                                  )}
                                                  key={index}
                                                  id={topic._id}
                                                  button
                                                  style={{
                                                    background: "none",
                                                    borderStyle:
                                                      checked.indexOf(
                                                        topic._id
                                                      ) !== -1
                                                        ? "solid"
                                                        : "none",
                                                    borderWidth: "2px",
                                                    borderColor:
                                                      (topic.parent_topic_name ===
                                                        "Environment" &&
                                                        "#DAF1E4") ||
                                                      (topic.parent_topic_name ===
                                                        "Social Capital" &&
                                                        "#EAD6F1") ||
                                                      (topic.parent_topic_name ===
                                                        "Leadership & Governance" &&
                                                        "#D6E3F1") ||
                                                      (topic.parent_topic_name ===
                                                        "Business Model & Innovation" &&
                                                        "#F1D6D6") ||
                                                      (topic.parent_topic_name ===
                                                        "Human Capital" &&
                                                        "#F6F3DA"),
                                                    borderRadius: "10px",
                                                  }}
                                                  onClick={handleToggle(
                                                    index,
                                                    topic._id
                                                  )}
                                                  disableRipple
                                                >
                                                  <ListItemAvatar>
                                                    {topic.parent_topic_name ===
                                                      "Environment" && (
                                                      <Avatar
                                                        className={
                                                          classes.envAvatar
                                                        }
                                                      >
                                                        {checked.indexOf(
                                                          topic._id
                                                        ) !== -1 && (
                                                          <CheckIcon fontSize="small" />
                                                        )}
                                                      </Avatar>
                                                    )}
                                                    {topic.parent_topic_name ===
                                                      "Social Capital" && (
                                                      <Avatar
                                                        className={
                                                          classes.socialAvatar
                                                        }
                                                      >
                                                        {checked.indexOf(
                                                          topic._id
                                                        ) !== -1 && (
                                                          <CheckIcon fontSize="small" />
                                                        )}
                                                      </Avatar>
                                                    )}
                                                    {topic.parent_topic_name ===
                                                      "Leadership & Governance" && (
                                                      <Avatar
                                                        className={
                                                          classes.govtAvatar
                                                        }
                                                      >
                                                        {checked.indexOf(
                                                          topic._id
                                                        ) !== -1 && (
                                                          <CheckIcon fontSize="small" />
                                                        )}
                                                      </Avatar>
                                                    )}
                                                    {topic.parent_topic_name ===
                                                      "Business Model & Innovation" && (
                                                      <Avatar
                                                        className={
                                                          classes.bussinessAvatar
                                                        }
                                                      >
                                                        {checked.indexOf(
                                                          topic._id
                                                        ) !== -1 && (
                                                          <CheckIcon fontSize="small" />
                                                        )}
                                                      </Avatar>
                                                    )}
                                                    {topic.parent_topic_name ===
                                                      "Human Capital" && (
                                                      <Avatar
                                                        className={
                                                          classes.humanAvatar
                                                        }
                                                      >
                                                        {checked.indexOf(
                                                          topic._id
                                                        ) !== -1 && (
                                                          <CheckIcon fontSize="small" />
                                                        )}
                                                      </Avatar>
                                                    )}
                                                  </ListItemAvatar>
                                                  <ListItemText
                                                    primary={
                                                      <Typography>
                                                        {topic.text}
                                                      </Typography>
                                                    }
                                                  />
                                                </ListItem>
                                              </List>
                                            );
                                          }
                                        )}
                                    </Box>
                                  </Box>
                                </Box>
                              </Slide>
                            )}
                          </Box>
                          {/* Back and Next Button */}
                          <Grid
                            item
                            xs={12}
                            className={classes.buttonContainer}
                          >
                            {!hideCards && framework ? (
                              <MyButtons
                                back={handleBackFramework}
                                next={() => handleFramework(frameworkId)}
                                disabled={frameworkId === ""}
                              />
                            ) : (
                              ""
                            )}

                            {!hideCards && factoryComp ? (
                              <MyButtons
                                back={() => {
                                  setFramework(true);
                                  setFactoryComp(false);
                                }}
                                next={() => {
                                  setFactoryComp(false);
                                  setTopic(true);
                                  getTopics(
                                    login.token ||
                                      localStorage.getItem("user_token"),
                                    frameworkId,
                                    selectedSectorData
                                  );
                                }}
                                disabled={selectedSectorData.length === 0}
                              />
                            ) : (
                              ""
                            )}
                            {!hideCards && topic ? (
                              <MyButtons
                                disabled={checked.length === 0}
                                back={() => {
                                  setTopic(false);
                                  setFactoryComp(true);
                                }}
                                next={handleTopic}
                              />
                            ) : (
                              ""
                            )}
                          </Grid>
                        </div>
                      </Slide>
                    )}

                    {show3 && (
                      <Slide
                        direction="up"
                        in={show3}
                        timeout={500}
                        mountOnEnter
                        unmountOnExit
                      >
                        <Card>
                          <CardContent>
                            <Grid container>
                              <Grid item xs={12} className={classes.grid4}>
                                <Typography
                                  variant="h6"
                                  className={classes.grid5}
                                >
                                  Thanks For Signing Up
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body2"
                                  className={classes.typo2}
                                >
                                  Thanks for signing up to our closed beta
                                  program. Our team will get in touch with you
                                  within 48 hours.
                                </Typography>
                              </Grid>
                              <Grid item xs={6} className={classes.grid6}>
                                <img
                                  src={
                                    "https://firebasestorage.googleapis.com/v0/b/nextstacks.appspot.com/o/imgrows%20email%20imgs%2FThank%20you%20card%20vector.svg?alt=media&token=531090f6-fa3b-402d-b708-9614e3edd32f"
                                  }
                                  className={classes.img}
                                  alt="img"
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Slide>
                    )}
                  </div>
                }
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </>
    );
}

export default React.memo(Onboaring);
