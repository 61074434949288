import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import LoginCon from "../../../containers/login/login-cont";
import ResetPasswordCon from "../../../containers/resetPasswordCont/resetPasswordCont";
import BasicDetailsCont from "../../../containers/basicDetails/basicDetailsCont";
import SurveyUp from "../../UI/maturityMatrix/SurveyUp";
// import SignupContainer from "../../../containers/signup/signupContainer";
import Drawer from "../../widgets/policies/drawer";
import {
  LoginContainer,
  SignupContainer,
  VerifyContainer,
} from "containers/phase-3/Auth";
// import Controller from "../../../containers/router/controllerCont";

const UnauthenticatedRoutes = () => {
  const routes = [
    { path: "/", element: <SignupContainer /> },
    { path: "/auto_login", element: <LoginCon /> },
    { path: "/login", element: <LoginContainer /> },
    { path: "/verify", element: <VerifyContainer /> },
    { path: "/drawer", element: <Drawer /> },
    { path: "/faq", element: <Drawer /> },
    { path: "/privacy_policy", element: <Drawer /> },
    { path: "/tnc", element: <Drawer /> },
    { path: "/reset_password", element: <ResetPasswordCon /> },
    { path: "/basicDetails", element: <BasicDetailsCont /> },
    { path: "/maturity_matrix", element: <SurveyUp /> },
    { path: "*", element: <Navigate to="/" /> },
    // { path: "*", element: <NotFound /> } // Optional: Handle unknown routes
  ];

  const element = useRoutes(routes);

  return element;
};

export default UnauthenticatedRoutes;
