import {
  SET_SUPPLIER_ASSESSMENT_DETAILS,
  SET_SUPPLIER_STAKEHOLDERS_LIST,
  SET_SUPPLIER_SURVEY_DOCUMENTS,
} from "constants/phase-3/supplierAssessment/supplierAssessmentConst";

const initial_state = {
  supplierAssessmentDetails: null,
  supplierStakeholdersList: [],
  supplierSurveyDocuments: [],
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case "RESET_STATE":
      return (state = {
        ...state,
        ...initial_state,
      });
    case SET_SUPPLIER_ASSESSMENT_DETAILS:
      return (state = {
        ...state,
        supplierAssessmentDetails: action.payload,
      });

    case SET_SUPPLIER_STAKEHOLDERS_LIST:
      return (state = {
        ...state,
        supplierStakeholdersList: action.payload,
      });
    case SET_SUPPLIER_SURVEY_DOCUMENTS:
      return (state = {
        ...state,
        supplierSurveyDocuments: action.payload,
      });

    default:
      return state;
  }
}
