import React, { Suspense } from "react";
import UnauthenticatedRoutes from "./routes/UnauthenticatedRoutes";
import Layout from "../layout/AuthRouteLayout";

const AuthenticatedRoutes = React.lazy(
  () => import("./routes/AuthenticatedRoutes")
);

const SignupProcessRoutes = React.lazy(
  () => import("./routes/SignupProcessRoutes")
);

function DetermineRoutes({
  isLoggedin,
  signupProcess,
  userType,
  isOpen,
  cb,
  ...props
}) {
  console.log("run");
  if (!isLoggedin) {
    return <UnauthenticatedRoutes />;
  } else if (signupProcess === 2 || signupProcess === 3) {
    return (
      <Suspense fallback={<div>Loading Signup Process...</div>}>
        <SignupProcessRoutes />
      </Suspense>
    );
  } else {
    return (
      <Suspense fallback={<div>Loading Authenticated Routes...</div>}>
        <Layout isOpen={isOpen} setIsOpen={cb} {...props}>
          <AuthenticatedRoutes userType={userType} />
        </Layout>
      </Suspense>
    );
  }
}

export default React.memo(DetermineRoutes);
