// ----------------------------------------------------------------------

export default function Tabs(theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        scrollButtons: {
          width: 48,
          borderRadius: "50%",
        },
        indicator: {
          height: 2,
          backgroundColor: theme.palette.secondary.main,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: "6px 6px",
          minHeight: 48,
          fontWeight: theme.typography.fontWeightMedium,
          borderTopLeftRadius: "5px", // theme.shape.borderRadius,
          borderTopRightRadius: "5px", // theme.shape.borderRadius,
          "&.Mui-selected": {
            color: theme.palette.primary.dark,
            fontWeight: theme.typography.fontWeightBold,
          },
          "&:not(.Mui-selected)": {
            color: theme.palette.grey[500_80],
          },
          "&:not(:last-of-type)": {
            marginRight: theme.spacing(2),
          },
          "@media (min-width: 600px)": {
            minWidth: 48,
          },
        },
        labelIcon: {
          minHeight: 48,
          flexDirection: "row",
          "& > *:first-of-type": {
            marginBottom: 0,
            marginRight: theme.spacing(1),
          },
        },
        wrapped: {
          flexDirection: "row",
          whiteSpace: "nowrap",
        },
        textColorInherit: {
          opacity: 1,
          color: theme.palette.text.secondary,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  };
}
