import makeStyles from "@mui/styles/makeStyles";

const drawerUseStyles = makeStyles((theme) => ({
  searchBox: {
    marginTop: "50px",
    backgroundColor: "#fff",
    width: "70%",
    height: "40px",
    borderRadius: "5px",
    [theme.breakpoints.down("xl")]: {
      marginTop: "28px",
      width: "100%",
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "28px",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "5px",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "5px",
      width: "100%",
    },
  },
  buttonstyle: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",
    borderRadius: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    color: "#3374B9",
    fontFamily: "Poppins",
    fontWeight: "600",
    textTransform: "none",
    fontSize: "large",
    "&:hover": {
      color: "white",
    },
  },

  appBar: {
    paddingTop: "10px",
    paddingBottom: "10px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "10px",
      paddingBottom: "20px",
    },
    backgroundColor: "#3374B9",
  },

  logo: {
    width: "29%",
  },

  largeText: {
    marginBottom: "-10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    color: "#FFFFFF",
    opacity: "0.45",
    fontSize: "100px",
    fontWeight: "normal",
    [theme.breakpoints.down("xl")]: {
      paddingTop: "00px",
      paddingBottom: "15px",
    },
    [theme.breakpoints.down("lg")]: {
      paddingTop: "00px",
      paddingBottom: "0px",
      paddingLeft: "50px",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "00px",
      paddingBottom: "20px",
      fontSize: "72px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0px",
      paddingBottom: "10px",
      fontSize: "72px",
    },
  },
  paddings: {
    color: "rgba(255, 255, 255, 0.8)",

    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
}));

export default drawerUseStyles;
