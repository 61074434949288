import {
  SET_INDUSTRY_ARRAY,
  SET_SECTOR_ARRAY,
} from "constants/phase-3/common/commonConst";

const initial_state = {
  sector: null,
  industry: null,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case "RESET_STATE":
      return (state = {
        ...state,
        ...initial_state,
      });
    case SET_SECTOR_ARRAY:
      return (state = {
        ...state,
        sector: action.payload,
      });

    case SET_INDUSTRY_ARRAY:
      return (state = {
        ...state,
        industry: action.payload,
      });

    default:
      return state;
  }
}

// ------------------- Important Key Points -------------------
