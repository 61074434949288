export const SET_EMAIL = "SET_EMAIL_USER";
export const SET_LOGIN = "SET_LOGIN";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_NAME = "SET_NAME";
export const SET_EMAIL_VALID = "SET_EMAIL_VALID";
export const SET_USER_PROFILE = "SET_USER_PROFILE";

export const LIMIT = 5;
export const JWT_SECRET = "5e3d7db17478a1024a3977dd";
export const SET_LOGOUT = "SET_LOGOUT";
export const BUSINESS_UNIT = "BUSINESS_UNIT";

export const SET_SIDE_IMAGE = "SET_SIDE_IMAGE";
export const SET_SIDE_MESSAGE = "SET_SIDE_MESSAGE";
export const SET_SHOW_ONBOARDING = "SET_SHOW_ONBOARDING";
export const SET_SIGNUP_CARD = "SET_SIGNUP_CARD";
export const SET_TOKEN = "SET_TOKEN";
export const SET_ON_BOARDING = "SET_ON_BOARDING";
export const SET_INDUSTRY_TYPES = "SET_INDUSTRY_TYPES";
export const SET_CURRENT_COMPANY_LOGO = "SET_CURRENT_COMPANY_LOGO";

export const SET_IS_SUBSCRIPTION_ACTIVE = "SET_IS_SUBSCRIPTION_ACTIVE";

//Signup Varables
export const SET_SIGNUP_NAME = "SET_SIGNUP_NAME";
export const SET_SIGNUP_EMAIL = "SET_SIGNUP_EMAIL";
export const SET_SIGNUP_PASSWORD = "SET_SIGNUP_PASSWORD";
export const SET_SIGNUP_PHONE = "SET_SIGNUP_PHONE";
export const SET_SIGNUP_LOGO = "SET_SIGNUP_LOGO";

export const SET_CURRENT_ORGANIZATION = "SET_CURRENT_ORGANIZATION";
export const SET_CURRENT_ORGANIZATION_USER_TYPE =
  "SET_CURRENT_ORGANIZATION_USER_TYPE";

export const SET_IS_ADD_NEW_COMP = "SET_IS_ADD_NEW_COMP";

export const SET_SELECTED_ORGANIZATION = "SET_SELECTED_ORGANIZATION";
export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const SET_SECTOR_DATA = "SET_SECTOR_DATA";
export const SET_TOPICS_DATA = "SET_TOPICS_DATA";
export const SET_FRAMEWORKS_DATA = "SET_FRAMEWORKS_DATA";
export const SET_SIGNUP_STEPNO = "SET_SIGNUP_STEPNO";
export const SET_SIGNUP_PROCESS_NO = "SET_SIGNUP_PROCESS_NO";

export const SET_VERSION_NO = "SET_VERSION_NO";
export const SET_HELPER_DAILOGUE = "SET_HELPER_DAILOGUE";
export const SET_IS_TUTORIAL_COMPLETED = "SET_IS_TUTORIAL_COMPLETED";
export const SET_TUTORIAL_COMPLETED_STEPS = "SET_TUTORIAL_COMPLETED_STEPS";

export const NEW_SET_LOGIN = "NEW_SET_LOGIN";
export const NEW_SET_LOGOUT = "NEW_SET_LOGOUT";
