import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import { Link } from "react-router-dom";
import userProfileCardUseStyles from "./userProfileCardStyles";
import DefaultUser from "../../../images/Default_user.svg";
import { userLogout } from "actions/phase-3/auth/authAction";
import { useDispatch } from "react-redux";
import { queryClient } from "index";

function UserProfileCard({
  userName,
  userEmail,
  token,
  logOut,
  loginDetails,
  anchorEl,
  onClose,
}) {
  const classes = userProfileCardUseStyles();
  const dispatch = useDispatch();
  return (
    <Menu
      id="user-profile-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={() => onClose()}
      className={classes.menu}
      getContentAnchorEl={null}
    >
      <Box className={classes.boxstyle}>
        <Box>
          <Typography variant="subtitle1" className={classes.typo}>
            {userName}
          </Typography>

          <Typography variant="body2">{userEmail}</Typography>
        </Box>
        <Avatar
          src={
            !!loginDetails.profile_img && loginDetails.profile_img.url
              ? loginDetails.profile_img.url
              : DefaultUser
          }
        />
      </Box>
      <div className={classes.divider}></div>
      <Link to="/login" className={classes.link}>
        <div
          className={classes.iconDiv}
          onClick={() => {
            dispatch(userLogout());
            queryClient.clear();
            dispatch({ type: "RESET_STATE" })
          }}
        >
          <Icon fontSize="small">logout</Icon>
          <Typography className={classes.typo1}>Logout</Typography>
        </div>
      </Link>
      <div className={classes.divider}></div>
    </Menu>
  );
}

export default React.memo(UserProfileCard);
