import { Box, Stack } from "@mui/material";
import AuthLeftBlue from "components/screen/phase-3/AuthLeftRight/AuthLeftBlue";
import AuthRightWrap from "components/screen/phase-3/AuthLeftRight/AuthRightWrap";
import VerifyForm from "components/widgets/phase-3/AuthRight/RightVerify/VerifyForm";
import SignupLoginHead from "components/widgets/phase-3/AuthRight/SignupLoginHead";

const VerifyContainer = () => {
  return (
    <Box width={"100%"} height={"100vh"} display={"flex"}>
      <AuthLeftBlue sx={{ display: { xs: "none", md: "block" } }} />
      <AuthRightWrap>
        <Stack
          width={"100%"}
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ p: { xs: 5, md: 15 } }}
        >
          {/* Main Box */}
          <Stack spacing={4} width={"100%"}>
            {/* Form Section */}
            <Stack spacing={2}>
              {/* Head */}
              <SignupLoginHead head={"Verify OTP"} />
            </Stack>

            {/* Form */}
            <VerifyForm />
          </Stack>
        </Stack>
      </AuthRightWrap>
    </Box>
  );
};
export default VerifyContainer;
