import React, { useEffect, useState } from "react";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import "../../../styles/login.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CustomTextfield from "../../../components/UI/textfield/textfield";
import logoWhite from "../../../images/ImpactGrows White Corrected.svg";
import user from "../../../images/Avatar.svg";
import leaf from "../../../images/leaf.svg";
import sideleft from "../../../images/sidebarLeft.png";
import sideright from "../../../images/sidebarRight.png";
import { LoginUseStyles } from "./loginStyle";

import LoginForgetPasswordDialog from "./loginForgotPasswordDialog";
import CustomButton from "../../UI/button/button";

const theme = createTheme(
  adaptV4Theme({
    typography: {
      body1: {
        fontSize: "12px",
      },
    },
    palette: {
      primary: {
        main: "#3374B9",
      },
    },
  })
);

function Login(props) {
  const {
    login,
    login_with_email,
    forgetPassword,
    loginFromOtherSources,
    getCompanyLogo,
  } = props;
  const classes = LoginUseStyles();
  const [checkBox, setCheckBox] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isForgetPasswordDialog, setForgetPasswordDialog] = useState(false);

  useEffect(() => {
    if (checkBox) {
      localStorage.setItem("keep_me_signed_in", true);
    } else {
      localStorage.setItem("keep_me_signed_in", false);
    }
  }, [checkBox]);

  useEffect(() => {
    getCompanyLogo(window.location.hostname);
  }, []);
  useEffect(() => {
    if (window.location.pathname === "/auto_login") {
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get("token");
      if (myParam !== null) {
        loginFromOtherSources(myParam);
      }
    }

    // let token =
    //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImJhaWJoYXYucmF5QGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiQmFpYmhhdjEyM0AiLCJpYXQiOjE2NjA4ODUxMjh9.AkzIYl_090mYdbSwWKnnmUHO_56Yr1d1LrfRZ6nDNy0';
    // if (window.location.pathname === 'auto_login') {
    //   // openInNewTab('/login')
    //   loginFromOtherSources(token);
    // }
  }, [window.location]);

  let token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImJhaWJoYXYucmF5QGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiQmFpYmhhdjEyM0AiLCJpYXQiOjE2NjA4ODUxMjh9.AkzIYl_090mYdbSwWKnnmUHO_56Yr1d1LrfRZ6nDNy0";

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
    loginFromOtherSources(token);
  };
  return (
    <>
      <Grid container className={classes.loginGridContainer}>
        <Grid item xs={3} className={classes.loginGridItem1}>
          <div className={classes.loginGridDiv}>
            {login.company_logo && login.company_logo.logo && (
              <img
                style={
                  JSON.parse(localStorage.getItem("logo_styles"))
                    ? JSON.parse(localStorage.getItem("logo_styles"))
                    : login.company_logo
                      ? login.company_logo.logo_tyles
                      : {}
                }
                src={
                  localStorage.getItem("logo")
                    ? localStorage.getItem("logo")
                    : login.company_logo.logo
                      ? login.company_logo.logo
                      : logoWhite
                }
                alt="logo"
              />
            )}
            {login.company_logo && !login.company_logo.logo && (
              <img src={logoWhite} alt="logo" />
            )}
          </div>
          <Grid container className="loginDiv">
            <Grid
              item
              container
              justifyContent="center"
              className={classes.loginGridAvatar}
            >
              <Grid item xs={10}>
                <img src={user} alt="user" className="avatar" />
              </Grid>
              <Grid item xs={10}>
                <Typography
                  className={classes.loginGridTypography}
                  variant="h6"
                >
                  Alright, <br />
                  Let's Start
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography className={classes.loginGridbody1} variant="body1">
                  On the right you Login section. Dont worry your data is 100%
                  secure & safe
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography className={classes.loginGridbody2} variant="body2">
                  Know More &gt; &gt;
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={10} className={classes.loginGridleaf}>
              <img src={leaf} alt="" />
              <img
                src={sideleft}
                alt="left"
                className={classes.loginGridSideLeft}
              />
              <img
                src={sideright}
                alt="right"
                className={classes.loginGridSideRight}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.loginGridPolicy}
            >
              <div className={classes.loginGridPolicy}>
                <ul className={classes.loginGridUl}>
                  <li className="footerList">
                    <a
                      className={classes.loginGridAnchor}
                      href="/faq"
                      target="_blank"
                    >
                      FAQ
                    </a>
                  </li>
                  <li className="footerList">
                    <a
                      className={classes.loginGridAnchor}
                      href="/tnc"
                      target="_blank"
                    >
                      Terms & Conditions{" "}
                    </a>
                  </li>
                  <li className="footerList">
                    <a
                      className={classes.loginGridAnchor}
                      href="/privacy_policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={9} className={classes.loginGridItem2}>
          {isForgetPasswordDialog && (
            <LoginForgetPasswordDialog
              forgetPassword={forgetPassword}
              forgetPasswordDialog={isForgetPasswordDialog}
              setForgetPasswordDialog={setForgetPasswordDialog}
            />
          )}
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Card className={classes.root} variant="outlined">
                <CardContent className={classes.cardContent}>
                  <CardHeader
                    title="Login"
                    titleTypographyProps={{
                      variant: "h6",
                      className: classes.cardHeader,
                    }}
                  />
                  <Divider className={classes.divider} />
                  <Typography className={classes.labelText} gutterBottom>
                    Enter Mail ID
                  </Typography>
                  <CustomTextfield
                    id="email"
                    name="email"
                    type="email"
                    margin="dense"
                    variant="outlined"
                    size="small"
                    className={classes.loginGridCardText}
                    value={email}
                    onKeyPress={(e) =>
                      e.key === "Enter" && login_with_email(email, password)
                    }
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <Typography className={classes.labelText} gutterBottom>
                    Enter Password
                  </Typography>
                  <CustomTextfield
                    id="password"
                    name="password"
                    type={isPasswordVisible ? "text" : "password"}
                    margin="dense"
                    variant="outlined"
                    size="small"
                    className={classes.loginGridCardText}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    onKeyPress={(e) =>
                      e.key === "Enter" && login_with_email(email, password)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          name="loginPassSvg"
                          position="end"
                          className={classes.loginGridInputAdorment}
                          onClick={() =>
                            setIsPasswordVisible(!isPasswordVisible)
                          }
                        >
                          {isPasswordVisible ? (
                            <VisibilityIcon fontSize="small" color="disabled" />
                          ) : (
                            <VisibilityOffIcon
                              fontSize="small"
                              color="disabled"
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="agreement"
                        color="primary"
                        disableRipple
                        id="agreement"
                        checked={checkBox}
                        onChange={() => setCheckBox(!checkBox)}
                      />
                    }
                    label="Keep me signed in"
                    className={classes.labelCheckbox}
                  />
                  <Box>
                    <Button
                      color="primary"
                      onClick={() => setForgetPasswordDialog(true)}
                    >
                      Forget password?
                    </Button>
                  </Box>
                  <Box className={classes.buttonBox}>
                    {/* <Button
                    style={{ marginRight: '335px' }}
                    variant="contained"
                    color="primary"
                    size="small"
                    disableElevation
                    // disabled={email === "" || password === ""}
                    onClick={() => {
                      // props.history.push('/')
                      openInNewTab('/auto_login');
                    }}
                  >
                    Login from other sites
                  </Button> */}

                    <CustomButton
                      name="loginBtn"
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      disabled={email === "" || password === ""}
                      type="submit"
                      onClick={() => login_with_email(email, password)}
                    >
                      Login
                    </CustomButton>
                  </Box>
                </CardContent>
              </Card>
            </ThemeProvider>
          </StyledEngineProvider>
        </Grid>
      </Grid>
    </>
  );
}
export default React.memo(Login);
