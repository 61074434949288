import { InputSelectIcon } from "./CustomIcons";

// ----------------------------------------------------------------------

export default function Select(theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon,
      },
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "2px", // Set border color for Select outlined variant
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: `${theme.palette.primary.main}99`, // Hover border color
          },
          // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "green", // Focused border color
          // },
          "& .MuiSelect-filled": {
            backgroundColor: theme.palette.background.paper,
          },
        },
      },
    },
  };
}
