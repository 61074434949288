import axios from "../axios/axios";
import encrypt from "actions/shared/sharedActions";
import { set_loader, unset_loader } from "actions/loader/loader_action";
import { set_snack_bar } from "actions/snackbar/snackbar_action";
import { reduxGet } from "./reduxGet";

const apiBolerplate = async ({
  url,
  bodyData = null,
  method = "post",
  extraParams = {},
  callback = () => {},
  files = null, // New parameter for file uploads
  hideLoader,
  hideMsg,
}) => {
  const { dispatch } = reduxGet;

  if (!hideLoader) {
    dispatch(set_loader());
  }

  let config = {
    method: method.toLowerCase(),
    url,
    headers: extraParams.headers || {},
  };

  if (files) {
    // Handle file uploads using FormData
    const formData = new FormData();

    if (bodyData) {
      const encData = encrypt(bodyData);
      formData.append("data", encData);
    }

    if (Array.isArray(files)) {
      files.forEach((file) => {
        formData.append("files", file);
      });
    } else if (files?.table_rows?.length > 0) {
      files?.document?.forEach((file) => {
        formData.append(file?.docId, file);
      });
    } else {
      Object.keys(files).forEach((key) => {
        formData.append(key, files[key]);
      });
    }

    // Add any extra parameters to FormData
    Object.keys(extraParams).forEach((key) => {
      formData.append(key, extraParams[key]);
    });

    config = {
      ...config,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  } else if (bodyData) {
    const encData = encrypt(bodyData);

    console.log(bodyData);

    config = {
      ...config,
      data: { data: encData, ...extraParams },
    };
  } else {
    config = {
      ...config,
      params: { ...extraParams },
    };
  }

  try {
    console.log(config);
    const { data } = await axios(config);
    if (!data?.status) {
      throw new Error(data?.message);
    }

    await callback(data);
    if (!hideMsg) {
      dispatch(set_snack_bar(true, data?.message));
    }
    // Return the data from the function
    return data;
  } catch (error) {
    console.error(error);
    dispatch(set_snack_bar(false, error?.message));
  } finally {
    if (!hideLoader) {
      dispatch(unset_loader());
    }
  }
};

export default apiBolerplate;
