import {
  SET_EMAIL,
  SET_PASSWORD,
  SET_NAME,
  SET_LOGOUT,
  SET_LOGIN,
  BUSINESS_UNIT,
  SET_SIDE_IMAGE,
  SET_SIDE_MESSAGE,
  SET_SHOW_ONBOARDING,
  SET_SIGNUP_CARD,
  SET_TOKEN,
  SET_ON_BOARDING,
  SET_INDUSTRY_TYPES,
  SET_SIGNUP_NAME,
  SET_SIGNUP_EMAIL,
  SET_SIGNUP_PASSWORD,
  SET_SIGNUP_PHONE,
  SET_SIGNUP_LOGO,
  SET_CURRENT_ORGANIZATION,
  SET_USER_PROFILE,
  SET_CURRENT_ORGANIZATION_USER_TYPE,
  SET_IS_ADD_NEW_COMP,
  SET_SELECTED_ORGANIZATION,
  SET_COMPANY_NAME,
  SET_SECTOR_DATA,
  SET_TOPICS_DATA,
  SET_FRAMEWORKS_DATA,
  SET_SIGNUP_STEPNO,
  SET_SIGNUP_PROCESS_NO,
  SET_VERSION_NO,
  SET_HELPER_DAILOGUE,
  SET_IS_TUTORIAL_COMPLETED,
  SET_TUTORIAL_COMPLETED_STEPS,
  SET_CURRENT_COMPANY_LOGO,
  SET_IS_SUBSCRIPTION_ACTIVE,
  NEW_SET_LOGIN,
  NEW_SET_LOGOUT,
} from "../../constants/login/ActionTypes";

const initial_state = {
  email: "",
  password: "",
  userEmail: "",
  name: "",
  userName: "",
  user_type: "",
  isLoggedIn: false,
  company_name: "",
  company_logo: "",
  company_type: "",
  employee_count: "",
  token: "",
  sideImage: "leaf",
  sideMessage: "signup",
  showOnboarding: false,
  signupCard: false,
  isOnBoarding: false,
  industryType: [],
  organisation_id: "",
  organisation_details: [],
  signupName: "",
  signupEmail: "",
  signupPassword: "",
  signupPhone: "",
  parent_organisation: [],
  current_organisation: "",
  userProfile: "",
  currentOrganisationUserType: "",
  isAddNewCompany: false,
  selectedOrganization: "",
  companyName: "",
  sectorData: [],
  frameworkData: [],
  topicsData: [],
  signupStepNo: 0,
  signupProcessNo: 0,
  versionNo: "",
  isShowHelperDailogue: false,
  isTutorialCompleted: false,
  tutorialCompletedSteps: [],
  user_id: "",
  isSubscriptionActive: false,

  // new login data
  newUserDetails: null,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case NEW_SET_LOGIN:
      return (state = {
        ...state,
        isLoggedIn: true,
        newUserDetails: action.payload,
      });

    case NEW_SET_LOGOUT:
      return (state = {
        ...state,
        isLoggedIn: false,
        newUserDetails: null,
      });

    case SET_LOGIN:
      return (state = {
        ...state,
        // token: action.payload.user_token,
        user_type: action.payload?.user_type,
        isOnBoarding: action.payload?.onBoarding,
        isLoggedIn: true,
        name: action.payload?.name,
        email: action.payload?.email,
        organisation_details: action.payload?.organisation_details,
        parent_organisation: action.payload?.parent_organisation,
        current_organisation: action.payload?.current_organisation,
        userProfile: action.payload?.profile_img,
        currentOrganisationUserType:
          action.payload?.currentOrganisationUserType,
        signupProcessNo: action.payload?.signup_process,
        user_id: action.payload?.user_id,
        payment_message: action.payload?.payment_message || "",
        payment_status: action.payload?.payment_status || "",
      });
    case SET_SIGNUP_PROCESS_NO:
      return (state = { ...state, signupProcessNo: action.payload });
    case SET_HELPER_DAILOGUE:
      return (state = { ...state, isShowHelperDailogue: action.payload });
    case SET_TOKEN:
      return (state = {
        ...state,
        token: action.payload,
      });
    case SET_TUTORIAL_COMPLETED_STEPS:
      return (state = { ...state, tutorialCompletedSteps: action.payload });
    case SET_SIGNUP_STEPNO:
      return (state = { ...state, signupStepNo: action.payload });
    case SET_IS_TUTORIAL_COMPLETED:
      return (state = { ...state, isTutorialCompleted: action.payload });
    case SET_VERSION_NO:
      return (state = { ...state, versionNo: action.payload });
    case SET_COMPANY_NAME:
      return (state = {
        ...state,
        companyName: action.payload,
      });
    case SET_SECTOR_DATA:
      return (state = { ...state, sectorData: action.payload });
    case SET_TOPICS_DATA:
      return (state = { ...state, topicsData: action.payload });
    case SET_FRAMEWORKS_DATA:
      return (state = { ...state, frameworkData: action.payload });
    case SET_IS_SUBSCRIPTION_ACTIVE:
      return (state = { ...state, isSubscriptionActive: action.payload });

    case SET_LOGOUT:
      return (state = {
        ...state,
        email: "",
        password: "",
        userEmail: "",
        name: "",
        userName: "",
        user_type: "",
        isLoggedIn: false,
        company_name: "",
        company_type: "",
        employee_count: "",
        token: "",
        sideImage: "leaf",
        sideMessage: "signup",
        showOnboarding: false,
        signupCard: false,
        isOnBoarding: false,
        industryType: [],
        organisation_id: "",
        organisation_details: [],
        signupName: "",
        signupEmail: "",
        signupPassword: "",
        signupPhone: "",
        parent_organisation: [],
        current_organisation: "",
        userProfile: "",
        currentOrganisationUserType: "",
        isAddNewCompany: false,
        selectedOrganization: "",
        isSubscriptionActive: false,
      });
    case SET_CURRENT_COMPANY_LOGO:
      return (state = { ...state, company_logo: action.payload });
    case SET_SIGNUP_LOGO:
      return (state = { ...state, company_logo: action.payload });
    case SET_EMAIL:
      return (state = { ...state, email: action.payload });
    case SET_PASSWORD:
      return (state = { ...state, password: action.payload });
    case SET_NAME:
      return (state = { ...state, name: action.payload });
    case SET_USER_PROFILE:
      return (state = { ...state, userProfile: action.payload });
    case BUSINESS_UNIT:
      return (state = { ...state, organisation_id: action.payload });
    case SET_SIDE_IMAGE:
      return (state = { ...state, sideImage: action.payload });
    case SET_SIDE_MESSAGE:
      return (state = { ...state, sideMessage: action.payload });
    case SET_SHOW_ONBOARDING:
      return (state = { ...state, showOnboarding: action.payload });
    case SET_SIGNUP_CARD:
      return (state = { ...state, signupCard: action.payload });
    case SET_ON_BOARDING:
      return (state = { ...state, isOnBoarding: action.payload });
    case SET_INDUSTRY_TYPES:
      return (state = { ...state, industryType: action.payload });
    case SET_SIGNUP_PHONE:
      return (state = { ...state, signupPhone: action.payload });
    case SET_SIGNUP_NAME:
      return (state = { ...state, signupName: action.payload });
    case SET_SIGNUP_EMAIL:
      return (state = { ...state, signupEmail: action.payload });
    case SET_SIGNUP_PASSWORD:
      return (state = { ...state, signupPassword: action.payload });
    case SET_CURRENT_ORGANIZATION:
      return (state = { ...state, current_organisation: action.payload });
    case SET_CURRENT_ORGANIZATION_USER_TYPE:
      return (state = {
        ...state,
        currentOrganisationUserType: action.payload,
      });
    case SET_IS_ADD_NEW_COMP:
      return (state = { ...state, isAddNewCompany: action.payload });
    case SET_SELECTED_ORGANIZATION:
      return (state = { ...state, selectedOrganization: action.payload });
    default:
      return state;
  }
}
