import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import makeStyles from "@mui/styles/makeStyles";
import "../../../styles/drawer.css";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import faqUseStyles from "./faqStyles";
import data from "../../../constants/policiesConst/faqData";

export default function FAQ() {
  const classes = faqUseStyles();

  const [expanded, setExpanded] = React.useState("");

  const onExpandChange = (title) => {
    if (expanded === title) {
      setExpanded("");
    } else {
      setExpanded(title);
    }
  };

  function changeBackground(e) {
    e.target.style.background = "#F5F6F8";
  }
  function normalBackground(e) {
    e.target.style.background = "white";
  }

  return (
    <>
      <Grid container className={classes.paddings}>
        <Grid item xs={12} className={classes.mainGrid}>
          <Grid container>
            <Grid item xs={3} md={3} className={classes.leftGrid}>
              <Typography
                variant="h6"
                color="primary"
                className={classes.texts}
              >
                <span
                  className={classes.name}
                  onMouseOver={changeBackground}
                  onMouseOut={normalBackground}
                >
                  General
                </span>
              </Typography>
            </Grid>
            <Grid item xs={9} md={6}>
              <div className="accordion">
                {data.map((s) => {
                  return (
                    <>
                      <Accordion
                        style={{
                          boxShadow: "0px 0px 0px 0px",
                          borderBottom: "0.5px solid rgba(0, 0, 0, 0.12)",
                          borderRadius: "0px",
                        }}
                      >
                        <AccordionSummary
                          style={{ padding: "12px" }}
                          expandIcon={
                            expanded !== s.title ? (
                              <Add />
                            ) : (
                              <Remove style={{ color: "#3374b9" }} />
                            )
                          }
                          aria-controls="panel1a-content"
                          onClick={() => onExpandChange(s.title)}
                        >
                          <Typography
                            id={expanded !== s.title ? "" : "question"}
                            className="que-div"
                          >
                            {s.title}
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails className="content">
                          <Typography className="ans-div">{s.desc}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
