import {
  SET_SEARCH_METRIC,
  SET_FILTER,
  SET_CHIP,
  SET_SELECT_BOX,
  SET_SHOW_ASSEST,
  SET_METRICS,
  SET_ASSIGN,
  SET_TOPIC_RELEVANCE,
  SET_MAIN_TOPICS,
  SET_TOPICS,
  SET_TOPICS_ARRAY,
  SET_FILES_ARRAY,
  SET_CURRENT_TOPIC_ID,
  RESET_TOPICS,
  SET_SEARCH_KEYWORD,
  SET_MEMBERS_ARRAY,
  SET_ADD_EMAIL,
  RESET_ADD_EMAIL,
  SET_ACTIVE_BUTTON,
  SET_ACTIVITY_LOG,
  SET_ASSIGNED_MEMBERS,
  RESET_MATERIALITY_ASSESSMENT,
  SET_SELECTED_METRICS,
  SET_TOPIC_NAME,
  SET_SELECTED_TOPICS,
  SET_IS_MATERIALITY_COMPLETED,
  SET_VOTE_DATA,
  SET_CURRENT_TOPIC_OBJECT,
  SET_CURRENT_MAIN_TOPIC_OBJECT,
  SET_IS_UPSERT,
} from "../../constants/sustainabilityReport/sustainabilityReportConst";

import {
  SET_MASTER_TOPICS,
  SET_TOPICS_DATA,
  SET_SELECTED_TOPIC_DATA,
  SET_TOPIC_TAB,
  SET_SELECTED_TOPIC_DETAILS,
  SET_GRAPH_DATA,
  SET_STAKEHOLDER_DATA,
  SET_ASSIGNED_STAKEHOLDER_DATA,
  SET_OPPORTUNITY,
  SET_RISK,
  SET_FILE,
  SET_WEIGHTAGE,
  SET_RELEVANCE,
  SET_MATERIALITY_MAP_TOTAL_PART,
  SET_MATERIALITY_MAP_RESPONDED,
  SET_TOTAL_TOPICS_COUNT,
  SET_TOPIC_FILTER,
  SET_ASSIGNED_MATERIALITY_TOPIC,
  SET_IS_SURVEY_COMPLETED,
  SET_TOPICS_COMPLETED_UPTO,
  SET_OBJECT_DIFFERENCE,
} from "../../constants/materialityConst/materialityConst";

import { LIMIT } from "../../constants/login/ActionTypes";

const initial_state = {
  showAsses: false,
  searchMetric: "",
  filter: "",
  chip: "",
  selectBox: false,
  metrics: [],
  selectedMetrics: [],
  assign: "",
  topicRelevance: "",
  mainTopics: [],
  topics: [],
  currentTopic: "",
  currentTopicId: "",
  topicsArray: [],
  filesArray: [],
  searchKeyword: "",
  limit: LIMIT,
  startingAfter: 0,
  membersArray: [],
  addEmail: "",
  activeStep: 0,
  activityLog: [],
  assignedMembers: [],
  topicName: "",
  selectedTopics: [],
  isCompleted: false,
  voteData: {},
  currentMainTopicObject: {},
  currentTopicObject: {},
  isUpsert: false,

  // STEP2 NEW
  masterTopics: [],
  topicData: [],
  selectedTopicsData: [],
  topicTab: 0,
  selectedTopicDetails: "",
  graphData: [],
  stakeholderData: [],
  assignedStakeholdersData: [],
  relevance: "",
  risks: "",
  opportunity: "",
  file: null,
  weightage: "",
  materialityMapTotalPart: "",
  materialityMapResponded: "",
  totalTopicCount: 0,
  topicFilter: "RECOMMENDED",
  assignedTopics: [],
  isSurveyCompleted: false,
  topicCompletedUpto: 0,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_TOPIC_NAME:
      return (state = { ...state, topicName: action.payload });
    case SET_ACTIVE_BUTTON:
      return (state = { ...state, activeStep: action.payload });
    case SET_SEARCH_METRIC:
      return (state = { ...state, searchMetric: action.payload });
    case SET_FILTER:
      return (state = { ...state, filter: action.payload });
    case SET_CHIP:
      return (state = { ...state, chip: action.payload });
    case SET_SELECT_BOX:
      return (state = { ...state, selectBox: action.payload });
    case SET_SHOW_ASSEST:
      return (state = { ...state, showAsses: action.payload });
    case SET_METRICS:
      return (state = { ...state, metrics: action.payload });
    case SET_SELECTED_METRICS:
      return (state = { ...state, selectedMetrics: action.payload });
    case SET_ASSIGN:
      return (state = { ...state, assign: action.payload });
    case SET_TOPIC_RELEVANCE:
      return (state = { ...state, topicRelevance: action.payload });
    case SET_MAIN_TOPICS:
      return (state = { ...state, mainTopics: action.payload });
    case SET_TOPICS:
      return (state = { ...state, topics: action.payload });
    case SET_TOPICS_ARRAY:
      return (state = { ...state, topicsArray: action.payload });
    case SET_FILES_ARRAY:
      return (state = { ...state, filesArray: action.payload });
    case SET_CURRENT_TOPIC_ID:
      return (state = { ...state, currentTopicId: action.payload });
    case SET_SEARCH_KEYWORD:
      return (state = { ...state, searchKeyword: action.payload });
    case SET_MEMBERS_ARRAY:
      return (state = { ...state, membersArray: action.payload });
    case SET_ADD_EMAIL:
      return (state = { ...state, addEmail: action.payload });
    case RESET_ADD_EMAIL:
      return (state = { ...state, addEmail: "" });
    case SET_IS_MATERIALITY_COMPLETED:
      return (state = { ...state, isCompleted: action.payload });
    case RESET_TOPICS:
      return (state = {
        ...state,
        topicRelevance: "",
        risks: "",
        opportunity: "",
        file: null,
        currentTopicId: "",
        weightage: "",
      });
    case SET_ACTIVITY_LOG:
      return (state = { ...state, activityLog: action.payload });
    case SET_ASSIGNED_MEMBERS:
      return (state = { ...state, assignedMembers: action.payload });
    case SET_SELECTED_TOPICS:
      return (state = { ...state, selectedTopics: action.payload });
    case SET_VOTE_DATA:
      return (state = { ...state, voteData: action.payload });
    case SET_CURRENT_TOPIC_OBJECT:
      return (state = { ...state, currentTopicObject: action.payload });
    case SET_CURRENT_MAIN_TOPIC_OBJECT:
      return (state = { ...state, currentMainTopicObject: action.payload });
    case SET_IS_UPSERT:
      return (state = { ...state, isUpsert: action.payload });
    case RESET_MATERIALITY_ASSESSMENT:
      return (state = {
        ...state,
        showAsses: false,
        searchMetric: "",
        filter: "",
        chip: "",
        selectBox: false,
        metrics: [],
        selectedMetrics: [],
        assign: "",
        topicRelevance: "",
        relevance: "",
        risks: "",
        opportunity: "",
        file: null,
        mainTopics: [],
        topics: [],
        currentTopic: "",
        currentTopicId: "",
        topicsArray: [],
        filesArray: [],
        searchKeyword: "",
        limit: LIMIT,
        startingAfter: 0,
        membersArray: [],
        addEmail: "",
        activeStep: 0,
        activityLog: [],
        assignedMembers: [],
        weightage: "",
        topicName: "",
        selectedTopics: [],
        isCompleted: false,
      });

    // STEP2 NEW
    case SET_MASTER_TOPICS:
      return (state = { ...state, masterTopics: action.payload });
    case SET_TOPICS_DATA:
      return (state = { ...state, topicData: action.payload });
    case SET_SELECTED_TOPIC_DATA:
      return (state = { ...state, selectedTopicsData: action.payload });
    case SET_TOPIC_TAB:
      return (state = { ...state, topicTab: action.payload });
    case SET_SELECTED_TOPIC_DETAILS:
      return (state = { ...state, selectedTopicDetails: action.payload });
    case SET_GRAPH_DATA:
      return (state = { ...state, graphData: action.payload });
    case SET_STAKEHOLDER_DATA:
      return (state = { ...state, stakeholderData: action.payload });
    case SET_ASSIGNED_STAKEHOLDER_DATA:
      return (state = { ...state, assignedStakeholdersData: action.payload });
    case SET_RELEVANCE:
      return (state = { ...state, relevance: action.payload });
    case SET_RISK:
      return (state = { ...state, risks: action.payload });
    case SET_OPPORTUNITY:
      return (state = { ...state, opportunity: action.payload });
    case SET_WEIGHTAGE:
      return (state = { ...state, weightage: action.payload });
    case SET_FILE:
      return (state = { ...state, file: action.payload });
    case SET_MATERIALITY_MAP_TOTAL_PART:
      return (state = { ...state, materialityMapTotalPart: action.payload });
    case SET_MATERIALITY_MAP_RESPONDED:
      return (state = { ...state, materialityMapResponded: action.payload });
    case SET_TOTAL_TOPICS_COUNT:
      return (state = { ...state, totalTopicCount: action.payload });
    case SET_TOPIC_FILTER:
      return (state = { ...state, topicFilter: action.payload });
    case SET_ASSIGNED_MATERIALITY_TOPIC:
      return (state = { ...state, assignedTopics: action.payload });
    case SET_IS_SURVEY_COMPLETED:
      return (state = { ...state, isSurveyCompleted: action.payload });
    case SET_TOPICS_COMPLETED_UPTO:
      return (state = { ...state, topicCompletedUpto: action.payload });
    case SET_OBJECT_DIFFERENCE:
      return (state = { ...state, upsert_assessment_topic: action.payload });
    default:
      return state;
  }
}
