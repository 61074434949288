import React, { useCallback, useRef } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import GetAppIcon from "@mui/icons-material/GetApp";
import jsPDF from "jspdf";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export function RadarChart(props) {
  const { chartEsgData, allData, basicInfo } = props;

  const { environmentObj, socialObj, governanceObj, businessObj, humanObj } =
    allData;
  const { ChartTabNo } = props;

  const bgColor = {
    id: "bgColor",
    beforeDraw: (chart, options) => {
      const { ctx, width, height } = chart;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    },
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 1, // Adjust max value based on your data range
      },
    },
    chartArea: {
      backgroundColor: "blue",
    },
    plugins: [bgColor],
  };

  const getOverallData = () => {
    // Extract the top-level category values (e.g., environmentObj.value)
    const labels = [
      "Environment",
      "Social Capital",
      "Leadership & Governance",
      "Business Model & Innovation",
      "Human Capital",
    ];

    const dataSetValues = [
      environmentObj?.fraction_value || 0,
      socialObj?.fraction_value || 0,
      governanceObj?.fraction_value || 0,
      businessObj?.fraction_value || 0,
      humanObj?.fraction_value || 0,
    ];

    return {
      labels,
      datasets: [
        {
          label: `Overall`,
          data: dataSetValues,
          backgroundColor: "rgba(255, 99, 00, 0.15)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  const getData = (dataObject) => {
    // Check if dataObject contains sub_category for specific tab
    const subCategories = dataObject?.sub_category || [];

    // Extract labels and values from sub_categories
    const labels = subCategories.map((sub) => sub.category);
    const dataSetValues = subCategories.map((sub) => sub.fraction_value);

    return {
      labels,
      datasets: [
        {
          label: `${dataObject?.category}`,
          data: dataSetValues,
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderColor: "blue",
          borderWidth: 1,
        },
      ],
    };
  };

  const ref = useRef(null);
  const refC1 = useRef(null);
  const refC2 = useRef(null);
  const refC3 = useRef(null);
  const refC4 = useRef(null);
  const refC5 = useRef(null);

  const download = useCallback((ref) => {
    const canvas = ref.current;
    const canvasImage = canvas.toBase64Image();
    let pdf = new jsPDF();
    pdf.addImage(canvasImage, "JPEG", 20, 20, 185, 150);
    pdf.save("mychart.pdf");
  }, []);

  return (
    <div style={{ padding: "10px 5px", height: "60vh" }}>
      {/* Overall chart for Tab 0 */}
      {ChartTabNo === 0 && (
        <>
          <GetAppIcon
            style={{ float: "right" }}
            onClick={() => {
              download(ref);
            }}
          />
          <Radar ref={ref} options={options} data={getOverallData()} />
        </>
      )}

      {/* Environment Tab */}
      {ChartTabNo === 1 && environmentObj && (
        <>
          <GetAppIcon
            style={{ float: "right" }}
            onClick={() => {
              download(refC1);
            }}
          />
          <Radar ref={refC1} options={options} data={getData(environmentObj)} />
        </>
      )}

      {/* Social Tab */}
      {ChartTabNo === 2 && socialObj && (
        <>
          <GetAppIcon
            style={{ float: "right" }}
            onClick={() => {
              download(refC2);
            }}
          />
          <Radar ref={refC2} options={options} data={getData(socialObj)} />
        </>
      )}

      {/* Governance Tab */}
      {ChartTabNo === 3 && governanceObj && (
        <>
          <GetAppIcon
            style={{ float: "right" }}
            onClick={() => {
              download(refC3);
            }}
          />
          <Radar ref={refC3} options={options} data={getData(governanceObj)} />
        </>
      )}

      {/* Business Tab */}
      {ChartTabNo === 4 && businessObj && (
        <>
          <GetAppIcon
            style={{ float: "right" }}
            onClick={() => {
              download(refC4);
            }}
          />
          <Radar ref={refC4} options={options} data={getData(businessObj)} />
        </>
      )}

      {/* Human Tab */}
      {ChartTabNo === 5 && humanObj && (
        <>
          <GetAppIcon
            style={{ float: "right" }}
            onClick={() => {
              download(refC5);
            }}
          />
          <Radar ref={refC5} options={options} data={getData(humanObj)} />
        </>
      )}
    </div>
  );
}

export default RadarChart;
