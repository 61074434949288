var data = [
  {
    title: "EFFECTIVE DATE",
    desc: [
      "These Terms of Use shall come into force with effect from 0000 hours of 15th October.",
    ],
  },
  {
    title: "APPLICATION AND ACCEPTANCE OF THE TERM",
    desc: [
      "i. Your use of the Platform and BillionLives’ services, features, functionality, software and products (collectively the “Services” hereinafter) is subject to the terms and conditions contained in this document as well as the privacy policy and any other rules and policies of the Platform that BillionLives may publish from time to time.",
      "ii.	You must read BillionLives Privacy Policy which governs the collection, use, and disclosure of personal information about Users. You accept the terms of the Privacy Policy and agree to the use of the personal information about you in accordance with the Privacy Policy. ",
    ],
  },
  {
    title: "PROVISION OF SERVICES",
    desc: [
      `i. You must register on the Platform in order to access and use the Services. Further, BillionLives reserves the right, without prior notice, to restrict access to or use of certain Services (or any features within the Services) subject to other conditions that BillionLives may impose in its discretion. `,
      "ii.	In case you avail services while accessing the Platform, that may be supported and/or provided by third party service provider(s), for all such services your contracting entity will be such third party service provider(s), as the case may be. BillionLives disclaims all liability for any claims that may arise pursuant to your use of services provided by such third party service provider(s).",
      "iii.	User agrees and confirms that any Services provided to you by BillionLives are on best efforts basis and BillionLives may engage services of third party service provider(s) to facilitate such Services to you. We shall not in any manner be liable to you for failure or delay in providing the Services or for any temporary disablement, permanent discontinuance of the Services by us or for any consequences resulting from such actions or reasons that are beyond our reasonable control.",
      "iv.	User acknowledges that the Services are being provided to you on a ‘as is’ and ‘as available’ basis and may be interrupted while browsing, transacting, using or uploading information on the Platform. User agrees that we reserve the right to suspend the Services, forthwith without assigning any reason whatsoever, at our sole discretion.",
      "v.	BillionLives may at any time with or without notice, withdraw, terminate, and/or suspend any or part of the Services without cause or in case of any breach of the Terms by the User. In addition, termination of any or part of any Services shall not impact provision of other services or other business arrangements or agreements which the User may have entered into with BillionLives. ",
    ],
  },
  {
    title: "ELIGIBILITY",
    desc: [
      "The Platform is available for use and access to Users who can form legally binding contracts under Indian Contract Act, 1872. For the purposes of these Terms, the term ‘persons’ shall mean any sole proprietor, firm, company, corporation, government, state or agency of a state or any association, trust, joint venture, consortium or partnership (whether or not having separate legal personality) or any other body corporate duly incorporated under the laws of India. ",
    ],
  },
  {
    title: "USER ACCOUNTS AND VERIFICATION OF ACCOUNT",
    desc: [
      "i.	User must be registered on the Platform to access or avail the Services for its commercial purposes. You agree and acknowledge that you will transact on the Platform only for your business purposes and not for personal use. Except with BillionLives’  approval, one User may only register one account on the Platform. BillionLives may cancel or terminate a User’s account if BillionLives has reasons to suspect that the User has concurrently registered or controlled two or more accounts. Further, BillionLives may reject User’s application, without assigning any reasons thereof, for registration for any other reason.",
      "ii.	A set of user ID and OTP (One Time Password) / password is unique to a single account. Any action triggered on your user account on the Platform or by using the unique OTP will be deemed to have been authorised by you and with your express consent. You shall be solely responsible for maintaining the confidentiality and security of your user ID and password and for all activities that occur under your account. You agree that all activities that occur under your account (including without limitation, posting any company or product information, clicking to accept any terms & conditions or rules, subscribing to or making any payment for any Services, sending emails using the Platform or other communications) will be deemed to have been authorized by you.",
      "iii.	When you access the Platform you are electronically communicating with BillionLives. BillionLives may communicate with you by e-mail, SMS, WhatsApp messages or messages through other modes of communication, phone call or by posting notices on the Platform or any other mode of communication. For contractual purposes, your consent to receive communications (including transactional, promotional and/or commercial messages) in the above manner, from BillionLives with respect to your use of the Platform and it shall be deemed by your continued use of the Platform that you agree and consent to receive any communications from BillionLives. ",
      "iv.	While registering the User account on the Platform you will be required to furnish details about you and with respect to your business including without limitation, business name, registration, address, phone number and/ or any other information that may be required by BillionLives to provide in relation to your business. You agree and acknowledge that we may directly or through a third-party service provider validate the information provided by you on the Platform. You agree to furnish additional information and provide documentary proof as may be requested by us, from time to time, for the purposes of verification of your user account information. If any information provided by you is found to be incorrect or misleading, BillionLives reserves its right to take appropriate steps as set forth under Clause 7 of these General Terms. For the purposes of verification of your account information, you agree that we may share your information with such third party service provider in accordance with the terms of the Privacy Policy. We reserve the right to seek additional information from you about you and your business, from time to time and you consent to provide such additional information to continue using the Platform.",
    ],
  },
  {
    title: "USERS GENERALLY",
    desc: [
      "i.	You agree that (a) you will not copy, reproduce, download, re-publish, sell, distribute or resell any Services or any information, text, images, graphics, video clips, sound, directories, files, databases or listings, etc. available on or through the Platform (the “Platform Content”), and (b) you will not copy, reproduce, download, compile or otherwise use any Platform Content for the purposes of operating a business that competes with BillionLives, or otherwise commercially exploiting the Platform Content or systematic retrieval of Platform Content from the Platform to create or compile, directly or indirectly, a collection, compilation, database or directory (whether through robots, spiders, automatic devices or manual processes).",
      "ii.	BillionLives may allow Users access to content, products or services offered by third parties through hyperlinks (in the form of word link, banners, channels or otherwise), API or otherwise to such third parties' web sites. You are cautioned to read such third parties terms and conditions and/or privacy policies before using the Platform with respect to such content, products or services that you may avail. You acknowledge that BillionLives has no control over such third parties' web sites and shall not be responsible or liable to anyone for such web sites, or any content, products or services made available on such web sites.",
      "iii.	You agree not to undertake any action which may undermine the integrity of BillionLives’  feedback system.",
      "iv.	You agree that the Services shall be availed by you only for commercial purposes. ",
      "v.	By posting or displaying any information, content or material (“User Content”) on the Platform or providing any User Content to BillionLives or our representative(s), you grant perpetual, worldwide, royalty-free, and sub-licensable license to BillionLives to display, transmit, distribute, reproduce, publish, translate, and otherwise use any or all of the User Content in any form, media, or technology now known or not currently known in any manner and for any purpose which may be beneficial to the operation of the Platform, the provision of any Services and/or the business of the User. You confirm and warrant to BillionLives that you have all the rights, power and authority necessary to grant the above license.",
      `vi.	User agrees, undertakes, and confirms that User’s use of Platform shall be strictly governed by the following binding principles: a.	User shall not host, display, upload, modify, publish, transmit, store, update or share any information which: 1.	belongs to another person and to which User does not have any right to; 2.	is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy including bodily privacy, hateful, racially or ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986 or otherwise inconsistent with or contrary to the laws in force; 3.	is misleading in any way; 4.	is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual; 5.	harasses or advocates harassment of another person; 6.	involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming" or messages using BillionLives’  communication Platform; 7.	promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;
8.	infringes upon or violates any third party's rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity;
9.	promotes an illegal or unauthorized copy of another person's copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;
10.	contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);
11.	provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;
12.	provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;
13.	contains video, photographs, or images of another person (with a minor or an adult);
14.	tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Platform or solicits passwords or personal identifying information for commercial or unlawful purposes from other Users;
15.	engages in commercial activities and/or sales without prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of "virtual" products related to the Platform. Throughout this Terms of Use, BillionLives’  prior written consent means a communication coming from BillionLives’  Legal Department, specifically in response to your request, and specifically addressing the activity or conduct for which you seek authorization;
16.	solicits gambling or engages in any gambling activity which, in sole discretion, believes is or could be construed as being illegal;
17.	interferes with another user’s use and enjoyment of the Platform or enjoyment of any similar Services;
18.	refers to any website or URL that, in sole discretion, contains material that is inappropriate for the Platform or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms;
19.	harm minors in any way;
20.	infringes any patent, trademark, copyright or other proprietary rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;
21.	violates any law for the time being in force;
22.	deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature or knowingly and intentionally communicates any information which is patently false or misleading in nature but may reasonably be perceived as a fact;
23.	impersonate another person;
24.	contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancel-bots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;
25.	threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.
26.	shall not be false, inaccurate or misleading;
27.	shall not, directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force.
28.	shall not create liability for BillionLives or cause BillionLives to lose (in whole or in part) the Services of our internet service provider ("ISPs") or other suppliers.
29.	is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person.
b.	You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or any content on the Platform, or in any way reproduce or circumvent the navigational structure or presentation of the Platform or any content on the Platform, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Platform. BillionLives reserves its right to bar any such activity.
c.	You shall not make any defamatory or denigrating statement(s) about BillionLives, or our brand name or domain name used by BillionLives including the terms BillionLives, billionlives.in, or otherwise act in any manner that might tarnish the reputation or standing, of BillionLives or Users on the Platform or otherwise tarnish or dilute any of BillionLives’  trademarks, service marks, trading name, or the goodwill associated with them. 
d.	You shall at all times ensure full compliance with the applicable provisions of the Information Technology Act, 2000 and rules thereunder as applicable and as amended from time to time.
e.	User shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform or to any server, computer, network, or to any of the Services offered on or through the Platform, by hacking, password "mining" or any other illegitimate means.
f.	Unless expressly permitted, User shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform nor breach the security or authentication measures on the Platform or any network connected to the Platform. User shall not reverse look-up, trace or seek to trace any information on any other User of or visitor to Platform, or any other User, including any account on the Platform not owned by User, to its source, or exploit the Platform or any service or information made available or offered by or through the Platform, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than User’s own information, as provided for by the Platform.
g.	Each User agrees to indemnify BillionLives, its affiliates, directors, employees, agents and representatives and to hold them harmless, from any and all damages, losses, claims and liabilities (including legal costs on a full indemnity basis) which may arise from or in connection with: (i) your submission, posting or display of any User Content; (ii) from your use of the Platform or Services; (iii) from your breach of the Terms or breach of any applicable laws, including tax laws; (iv) any service availed by you from a third party service provider using any dispute inter-se Users; and/or (vi) your negligence or wilful misconduct.`,
    ],
  },
  {
    title: "BREACHES AND SUSPENSION",
    desc: [
      `i.	If any User breaches any Terms, or if BillionLives has reasonable grounds to believe that a User is in breach of any Terms, or could subject BillionLives or its affiliates to liability, or is otherwise found inappropriate or unlawful in BillionLives’  opinion, BillionLives shall have the right to take such disciplinary actions as it deems appropriate, including without limitation: 
      a.	suspending or terminating the User’s account and any and all accounts determined to be related to such account by BillionLives in its discretion;
      b.	blocking, restricting, downgrading, suspending or terminating the subscription of, access to, or current or future use of any Service; 
      c.	removing any product listings or other User Content that the User has submitted, posted or displayed;
      d.	withhold settlement of payments by BillionLives to the User;
      e.	any other corrective actions, discipline or penalties as BillionLives may deem necessary or appropriate in its sole discretion.`,
      `ii.	BillionLives does not pre-screen any content or information posted, published or transmitted on the Platform by the users and BillionLives is under no obligation to pre-screen any such content or information. However, BillionLives may at its discretion and/or in accordance with applicable law may voluntarily take down any content or information posted by you on the Platform and if BillionLives determines that any content or information is in violation of these Terms, BillionLives may remove such content or information from the Platform without notice. Such actions do not in any manner negate or dilute BillionLives’  position as an intermediary or impose any liability on BillionLives with respect to content or information posted, published or transmitted by users on the Platform. `,
      `iii.	Notwithstanding anything contained herein these Terms, BillionLives may with or without notice and in its sole discretion be entitled to suspend, reduce visibility of the product listings, de-activate, or de-list any product listings or User’s account for any reasons, including without limitation, economic constraints, operational difficulties, financial implications, usage behaviour of the User on the Platform, performance of the User on the Platform, etc.`,
      `iv.	In the event a User becomes inactive or if no transaction is noticed by BillionLives, in such a case BillionLives reserves its right to delist, deactivate or suspend a User’s account in its sole discretion, with or without giving any notice to the User. `,
      `v.	BillionLives reserves the right to cooperate fully with governmental authorities, private investigators, injured third parties in the investigation of any suspected criminal or civil wrongdoing and/or any third parties alleging a claim against you. Further, BillionLives may disclose the User's identity and contact information, if requested by any third party, government or law enforcement body, an injured third party, or as a result of a subpoena or other legal action. `,
      `vi.	If it comes to the knowledge of BillionLives or BillionLives reasonably believes that any User has availed any Services to obtain any product/service for its personal use or consumption or for any purpose other than a commercial purpose, BillionLives shall have the right in its sole discretion, without intimation to the User, to take action such as but not limited to suspending or terminating the User’s account and any and all accounts determined to be relation to such account.`,
    ],
  },
  {
    title: "FORCE MAJEURE",
    desc: [
      "i.	Under no circumstances shall BillionLives be held liable for any losses, delay or failure or disruption of the content or services delivered through the Platform resulting directly or indirectly from acts of nature, forces or causes beyond our reasonable control, including without limitation, internet failures, computer, telecommunications or any other equipment failures, electrical power failures, strikes, labour disputes, riots, insurrections, civil disturbances, shortages of labour or materials, fires, flood, storms, explosions, acts of God, war, governmental actions, epidemics, pandemics, curfews, lock-down, orders of domestic or foreign courts or tribunals or non-performance of third parties.",
    ],
  },
  {
    title: "INTELLECTUAL PROPERTY RIGHTS",
    desc: [
      "i.	BillionLives is the sole owner or lawful licensee of all the rights and interests in the Platform and the Platform Content. All title, ownership and intellectual property rights in the Platform and Platform Content shall remain with BillionLives or licensors of the Platform Content, as the case may be. All rights not otherwise claimed under the Terms or by BillionLives are hereby reserved.",
      `ii.	"BillionLives" and any other related icons and logos are registered trademarks of BilllionLives Business Initiatives Private Limited, in various jurisdictions and are protected under applicable copyright, trademark and other proprietary rights laws. The unauthorized copying, modification, use or publication of these marks is strictly prohibited. `,
      `iii.	BillionLives may, at its sole discretion, permit the User(s) of the Platform, in writing, to use “BillionLives” and any other related icons and logos for indicative purposes in the form and manner and terms and conditions as maybe agreed by BillionLives.`,
      `iv.	All text, graphics, photographs, trademarks, logos, and artwork available or accessible on the Platform are third party user generated content and BillionLives has no control over such third-party user generated content as BillionLives is merely an intermediary for the purposes of these Terms.`,
      `v.	You shall be solely responsible for any content or information posted or transmitted on the Platform and shall indemnify BillionLives against any claim or liability arising from any content or information posted or transmitted by You on the Platform. Any content or information such as but not limited to images, text, videos posted or transmitted on the Platform shall be licenced to BillionLives by the User uploading such content and BillionLives shall have the worldwide, fully paid-up, perpetual and transferable licence in such content or information for the purposes of its use on the Platform and for any purposes BillionLives deems fit. You shall not be entitled to any payment or compensation for any usage of the content by BillionLives.`,
    ],
  },
  {
    title: "NOTICES",
    desc: [
      `i.	All legal notices or demands to or upon BillionLives shall be made in writing and sent to BillionLives personally, by courier, certified mail, or facsimile to the following entity and address: Shreyas, VTRRA-14, Vytilla, Kerala. Pin 682019. Attn: Legal Department. The notices shall be effective when they are received by BillionLives in any of the above-mentioned manner.`,
      `ii.	All legal notices or demands to or upon a User shall be effective if either delivered personally, sent by courier, certified mail, by facsimile or email to the last-known correspondence, fax or email address provided by the User to BillionLives, or SMS, WhatsApp messages, or in-app notifications, or by posting such notice or demand on an area of the Platform that is publicly accessible without a charge or through such other mode of communication as BillionLives may deem fit in its discretion. Notice to a User shall be deemed to be received by such User if and when, a) BillionLives is able to demonstrate that communication, whether in physical or electronic form, has been sent to such User, or b) immediately upon BillionLives posting such notice on an area of the Platform that is accessible by the User or publicly accessible without charge.`,
    ],
  },
  {
    title: "MISCELLANEOUS PROVISIONS",
    desc: [
      `i.	Unless otherwise communicated to you by BillionLives, the Terms constitute the entire agreement between User and BillionLives and govern the User’s use of the Platform and any of the Services. The Terms shall supersede any prior written or oral agreements that you may have had in relation to the use of the Platform and any of the Services. `,
      `ii.	BillionLives and User are independent contractors, and no agency, partnership, joint venture, employee-employer relationship is intended or created by the Terms. The relationship between you and us is one of independent contractors, and nothing contained in these Terms will be construed to (a) give either party the power to direct and control the day-to-day activities of the other, (b) constitute the parties as partners, joint ventures, co-owners or otherwise as participants in a joint or common undertaking, or (c) allow you to create or assume any obligation on our behalf for any purpose whatsoever.`,
      `iii.	If any provision of the Terms is held to be invalid or unenforceable, such provision shall be deleted and the remaining provisions shall remain valid and be enforced.`,
      `iv.	BillionLives’  failure to enforce any right or failure to act with respect to any breach by User under the Terms will not constitute a waiver of that right nor a waiver of BillionLives’  right to act with respect to subsequent or similar breaches.`,
      `v.	BillionLives shall have the right to assign the Terms (including all of our rights, titles, benefits, interests, and obligations and duties in the Terms to any person or entity (including any affiliates of BillionLives). User may not assign, in whole or part, the Terms to any third party or person.`,
      `vi.	The Terms shall be governed by the laws of India and the parties to the Terms agree to submit to the exclusive jurisdiction of the courts of Bangalore, Karnataka, India.`,
    ],
  },
];

export default data;
