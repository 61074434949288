// ----------------------------------------------------------------------

import { pxToRem, responsiveFontSizes } from "utils/getFontValue";

const FONT_PRIMARY = "poppins, sans-serif";

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontWeight: 700,
    lineHeight: pxToRem(28),
    fontSize: pxToRem(40),
    // letterSpacing: 2,
    ...responsiveFontSizes({ xs: 20, sm: 22, md: 24 }),
  },
  h2: {
    fontWeight: 700,
    lineHeight: pxToRem(24),
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ xs: 16, sm: 18, md: 22 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: pxToRem(20),
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ xs: 16, sm: 18, md: 18 }),
  },
  h4: {
    fontWeight: 600,
    lineHeight: pxToRem(20),
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ xs: 14, sm: 19, md: 14 }),
  },
  h5: {
    fontWeight: 500,
    lineHeight: pxToRem(16),
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ xs: 12, sm: 16, md: 12 }),
  },
  h6: {
    fontWeight: 400,
    lineHeight: pxToRem(16),
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ xs: 12, sm: 16, md: 11 }),
  },
  subtitle1: {
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(15),
    ...responsiveFontSizes({ xs: 12, sm: 12, md: 15 }),
    // color: "#000000A1",
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(13),
    ...responsiveFontSizes({ xs: 12, sm: 12, md: 13 }),
    // color: "#000000A1",
  },
  body1: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ xs: 14, sm: 14, md: 16 }),
  },
  body2: {
    fontWeight: 400,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ xs: 12, sm: 14, md: 14 }),
  },
  caption: {
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    // color: "#000000",
  },
  overline: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: "none",
  },
  button: {
    fontWeight: 600,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: "none",
  },
  article: {
    fontWeight: 600,
  },
};

export default typography;
