import {
  SET_ESG_COMPLIANCE_ASSESSMENT_DETAILS,
  SET_ORIGINAL_ESG_COMPLIANCE_ASSESSMENT_DETAILS,
  SET_SELECTED_COMPLIANCE_ROW_DATA,
} from "constants/phase-3/esgCompliance/esgSComplianceConst";

const initial_state = {
  esgComlianceAssessmentDetail: {},
  originalEsgComlianceAssessmentDetail: {},
  selectedRowData: null,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case "RESET_STATE":
      return (state = {
        ...state,
        ...initial_state,
      });
    case SET_ESG_COMPLIANCE_ASSESSMENT_DETAILS:
      return (state = {
        ...state,
        esgComlianceAssessmentDetail: action.payload,
      });

    case SET_ORIGINAL_ESG_COMPLIANCE_ASSESSMENT_DETAILS:
      return (state = {
        ...state,
        originalEsgComlianceAssessmentDetail: action.payload,
      });

    case SET_SELECTED_COMPLIANCE_ROW_DATA:
      return (state = {
        ...state,
        selectedRowData: action.payload,
      });

    default:
      return state;
  }
}
