import {
  SET_COMPANY_NAME,
  SET_COMPANY_TYPE,
  SET_EMPLOYEE_COUNT,
  RESET_BUSINESS_UNIT,
} from "../../constants/businessUnit/businessUnitConstants";

const initial_state = {
  comppanyName: "",
  comppanyType: "",
  employeeCount: "",
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_COMPANY_NAME:
      return (state = { ...state, comppanyName: action.payload });
    case SET_COMPANY_TYPE:
      return (state = { ...state, comppanyName: action.payload });
    case SET_EMPLOYEE_COUNT:
      return (state = { ...state, comppanyName: action.payload });
    case RESET_BUSINESS_UNIT:
      return (state = {
        ...state,
        comppanyName: "",
        comppanyType: "",
        employeeCount: "",
      });
    default:
      return state;
  }
}
