import {
  SET_COMPANY_DETAILS,
  SET_PROFILE_DETAILS,
} from "constants/generalSettings/generalSettings";

const initial_state = {
  profileDetails: null,
  companyDetails: null,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_PROFILE_DETAILS:
      return (state = { ...state, profileDetails: action.payload });

    case SET_COMPANY_DETAILS:
      return (state = { ...state, companyDetails: action.payload });
    default:
      return state;
  }
}
