import apiBolerplate from "utils/_helpers/apiBolerplate";
import axios from "utils/axios/axios";
import {
  SET_LOGOUT_USER,
  SET_ONBOARD_DATA,
  SET_USER_DETAILS,
} from "constants/phase-3/auth/userConst";
import { history } from "utils/_helpers/history";

// ----------------- Payloads -----------------
export function setUserDetails(payload) {
  return {
    type: SET_USER_DETAILS,
    payload: payload,
  };
}

export function setOnBoardData(payload) {
  return {
    type: SET_ONBOARD_DATA,
    payload: payload,
  };
}

export function setLogoutUser(payload) {
  return {
    type: SET_LOGOUT_USER,
    payload: payload,
  };
}

export const signupProcessLoginCase = (data) => {
  return async (dispatch) => {
    const signup_process = data?.result?.userDetails?.signup_process;

    switch (signup_process) {
      case 1:
        dispatch(
          setUserDetails({
            ...data?.result?.userDetails,
            isLoggedin: false,
          })
        );
        localStorage.setItem("hasLogin", false);
        history.navigate("/verify");
        break;

      case 2:
      case 3:
        dispatch(
          addUserLogin(data?.result?.accessToken, data?.result?.userDetails)
        );
        history.navigate("/onboard_details");
        break;

      default:
        dispatch(
          addUserLogin(data?.result?.accessToken, data?.result?.userDetails)
        );
        history.navigate("/");
        break;
    }
  };
};

// ----------------- Actions -----------------
export function addUserLogin(accessToken, userDetails) {
  return async (dispatch) => {
    // set token to axios
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    dispatch(setUserDetails({ ...userDetails, isLoggedin: true }));
    localStorage.setItem("hasLogin", true);
  };
}

export function userSignup(
  name,
  email,
  password,
  entity_name,
  redirectToVerify
) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/user/signup",
      bodyData: { name, email, password, entity_name },
      callback: (data) => {
        // final actions
        dispatch(setUserDetails({ email: email, signup_process: 1 }));

        if (Boolean(redirectToVerify)) {
          redirectToVerify();
        }
      },
    });
  };
}

export function userLogin(email, password) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/user/login",
      bodyData: { email, password },
      callback: (data) => {
        // final actions

        dispatch(signupProcessLoginCase(data));
      },
    });
  };
}

export function userVerifyOTP(email, otp) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/user/verify_otp",
      bodyData: { email, otp },
      callback: (data) => {
        // final actions
        dispatch(
          addUserLogin(data?.result?.accessToken, data?.result?.userDetails)
        );
        history.navigate("/onboard_details");
      },
    });
  };
}

export function userLogout() {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/user/logout",
      callback: (data) => {
        // final actions
        localStorage.removeItem("hasLogin");
        dispatch(setLogoutUser());
        history.navigate("/login");
      },
    });
  };
}

export function get_user_details(navigateUser = true, extraFunc) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/user/me",
      method: "get",
      callback: (data) => {
        // final actions
        // update user data in redux
        dispatch(
          setUserDetails({
            ...data?.result,
            isLoggedin: true,
          })
        );

        const signup_process = data?.result?.signup_process;

        if (navigateUser) {
          switch (signup_process) {
            case 1:
              history.navigate("/verify");
              break;

            case 2:
            case 3:
              history.navigate("/onboard_details");
              break;

            default:
              // history.navigate("/");
              break;
          }
        }

        if (Boolean(extraFunc)) {
          extraFunc();
        }
      },
    });
  };
}

export function refresh_token() {
  return async (dispatch) => {
    const data = await apiBolerplate({
      dispatch,
      url: "/user/refresh",
      callback: (data) => {
        // final actions
        // set token to axios
        axios.defaults.headers.common.Authorization = `Bearer ${data.result.accessToken}`;

        dispatch(get_user_details());
      },
    });

    // return token for further use
    return data.result.accessToken;
  };
}

export function addOnBoardData(field, value) {
  return async (dispatch, getState) => {
    const { onBoardData } = getState().user;

    dispatch(setOnBoardData({ ...onBoardData, [field]: value }));
  };
}

export function createParentEntity(
  entity_id,
  entity_name,
  sector_id,
  industry_id,
  isin,
  setStep,
  setHasChanges
) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/entity/create_parent_entity",
      bodyData: { entity_id, entity_name, sector_id, industry_id, isin },
      callback: (data) => {
        // final actions
        dispatch(get_user_details({ navigateUser: false }));
        if (Boolean(setStep) && Boolean(setHasChanges)) {
          setStep((prev) => prev + 1);
          setHasChanges(false);
        }
      },
    });
  };
}

export function userUserData(
  work,
  category,
  goals,
  how_you_found_us,
  entity_id,
  setStep,
  setHasChanges
) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/user/update_user",
      bodyData: { work, category, goals, how_you_found_us, entity_id },
      callback: (data) => {
        // final actions
        if (Boolean(setStep) && Boolean(setHasChanges)) {
          setStep((prev) => prev + 1);
          setHasChanges(false);
        }
      },
    });
  };
}
