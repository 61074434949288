// ----------------------------------------------------------------------

export default function Divider(theme) {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          // Custom color for the divider
          borderColor: `${theme.palette.text.primary}60`,
          "&:before": {
            backgroundColor: `${theme.palette.text.primary}60`,
          },
          "&:after": {
            backgroundColor: `${theme.palette.text.primary}60`,
          },
          // divider text stylings
          color: `${theme.palette.text.primary}80`,
          fontWeight: 600,
        },
      },
    },
  };
}
