import React from "react";
import Router from "./containers/router/routerCont";
import ThemeProvider from "./theme";

export default class App extends React.Component {
  render() {
    return (
      <>
        <ThemeProvider>
          <Router />
        </ThemeProvider>
      </>
    );
  }
}

/*

import React from "react";
import Router from "./containers/router/routerCont";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material/styles";
export default class App extends React.Component {
  render() {
    return (
      <>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Router />
          </ThemeProvider>
        </StyledEngineProvider>
      </>
    );
  }
}
const theme = createTheme(
  adaptV4Theme({
    typography: {
      fontFamily: ["poppins", "sans-serif"].join(","),
    },
  })
);

*/
