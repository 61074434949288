import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { refresh_token } from "actions/phase-3/auth/authAction";

// for adding navigations
import { history } from "utils/_helpers/history";

export default function LoginController(props) {
  history.navigate = useNavigate();
  history.location = useLocation();

  const dispatch = useDispatch();

  const { login, setLogin, getVersionNo, setIsSubscriptionActive, setToken } =
    props;

  const hasLogin = localStorage.getItem("hasLogin") || false;


  if (JSON.parse(hasLogin)) {
    dispatch(refresh_token());
  }

  const [redirect, setRedirect] = useState(false);
  const [redirectToDetails, setRedirectToDetails] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [redirectToGetStarted, setRedirectToGetStarted] = useState(false);
  const [versionNo, setVersionNo] = useState(false);
  const [userType, setUserType] = useState("");
  const [redirectToOtp, setRedirectToOtp] = useState(false);
  const [redirectToTnc, setRedirectToTnc] = useState(false);

  const isKeepSignedIn = JSON.parse(localStorage.getItem("keep_me_signed_in"));
  const onIdle = () => {
    if (!isKeepSignedIn) {
      localStorage.clear();
      setRedirectToLogin(true);
      window.location.reload(true);
    }
  };
  const idleTimer = useIdleTimer({ onIdle, timeout: 20 * 60 * 1000 });

  const localStorageUserType = localStorage.getItem(
    "current_organisation_user_type"
  );

  const completedSteps = JSON.parse(localStorage.getItem("completed_steps"));

console.log("RedirectToLogin:", redirectToLogin);
console.log("Version No Mismatch:", versionNo);
console.log("LocalStorage user_type:", localStorage.getItem("user_type"));
console.log("Current Path:", window.location.pathname);
 

  useEffect(() => {
    setUserType(localStorageUserType);
  }, [localStorageUserType]);

  useEffect(() => {
    if (login.token === "" && localStorage.getItem("user_token") !== null) {
      if (login.token === "") {
        setIsSubscriptionActive(
          localStorage.getItem("status") === "EXPIRED" ? false : true
        );
        setLogin({
          user_token: localStorage.getItem("user_token"),
          user_type: localStorage.getItem("user_type"),
          userName: localStorage.getItem("userName"),
          userEmail: localStorage.getItem("userEmail"),
          name: localStorage.getItem("userName"),
          email: localStorage.getItem("userEmail"),
          parent_organisation: JSON.parse(
            localStorage.getItem("parent_organisation")
          ),
          location:
            localStorage.getItem("status") === "EXPIRED"
              ? localStorage.setItem("location", "/general_settings")
              : localStorage.setItem("location", ""),
          current_organisation: localStorage.getItem("current_organisation"),
          profile_img: JSON.parse(localStorage.getItem("userProfile")),
          onBoarding: true,
          currentOrganisationUserType: localStorage.getItem(
            "current_organisation_user_type"
          ),
          signup_process: +localStorage.getItem("signup_process_no"),
          status_message: localStorage.getItem("status_message") || "",
          payment_status: localStorage.getItem("status") || "",
        });
      }
    } else if (
      login.token &&
      login.signupProcessNo === 4 &&
      completedSteps &&
      completedSteps.length !== 0
    ) {
      setRedirect(true);
      setRedirectToDetails(false);
      setRedirectToGetStarted(false);
      setRedirectToTnc(false)
    } else if (
      login.token !== "" &&
      (login.signupProcessNo === 2 || login.signupProcessNo === 3)
    ) {

      setRedirectToDetails(true);
    } else if (login.token !== "" && login.signupProcessNo === 1) {

      setRedirectToOtp(true);
    } else if (
      login.token &&
      login.signupProcessNo === 4 &&
      completedSteps &&
      completedSteps.length !== 0
    ) {

      setRedirect(false);
    } else {
      setRedirectToTnc(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (login.token && completedSteps && completedSteps.length > 0) {
      setRedirectToGetStarted(false);
    } else if (
      login.token &&
      completedSteps &&
      completedSteps.length === 0 &&
      login.signupProcessNo === 4 &&
      userType === "SA"
    ) {
      setRedirectToGetStarted(true);
    }
  }, [completedSteps]);

  // useEffect(() => {
  //   var hours = 2;
  //   var now = new Date().getTime();
  //   var setupTime = localStorage.getItem('setupTime');
  //   if (setupTime == null) {
  //     localStorage.setItem('setupTime', now);
  //   } else {
  //     if (now - setupTime > hours * 60 * 60 * 1000) {
  //       localStorage.clear();
  //       localStorage.setItem('setupTime', now);
  //       setRedirectToLogin(true);
  //     }
  //   }
  // }, [localStorage.getItem('setupTime')]);

  useEffect(() => {
    if (localStorage.getItem("user_token")) {
      // getVersionNo();
      if (login.versionNo) {
        let oldVersionNo = localStorage.getItem("version_no");
        if (oldVersionNo && oldVersionNo !== login.versionNo) {
          setVersionNo(true);
          localStorage.clear();
        }
      }
    }
  }, [login.versionNo]);

  if (redirectToLogin || versionNo) {
    return <Navigate to="/login"></Navigate>;
  }

  if (redirectToDetails && window.location.pathname !== "/privacy_policy") {

    return <Navigate to="/basicDetails" />;
  }

  if (redirectToGetStarted) {

    return <Navigate to="/get_started" />;
  }
  // if (login.token &&!login.isSubscriptionActive && localStorage.getItem('status')!=='ACTIVE') {
  //   return <Redirect to="/general_settings" />;
  // }

  if (
    redirect &&
    !login.isAddNewCompany &&
    login.tutorialCompletedSteps.length > 0 &&
    !window.location.pathname === "/privacy_policy"
  ) {
    if (!login.isSubscriptionActive) {

      return <Navigate to="/general_settings" />;
    } else if (userType === "SA" && login.isSubscriptionActive) {

      return <Navigate to="/survey_listing" />;
    } else if (userType === "A" && login.isSubscriptionActive) {

      return <Navigate to="/report_listing" />;
    } else {
    }
  }
  if (redirectToOtp && window.location.pathname !== "/privacy_policy") {

    return (
      <>
        <Navigate to="/" />
      </>
    );
  }
  if(redirectToTnc)
  {
    return <Navigate to="/privacy_policy" />;
  }
  if ( window.location.pathname === "/privacy_policy") {

    return <Navigate to="/privacy_policy" />;
  }
  if ( window.location.pathname === "/tnc") {

    return <Navigate to="/tnc" />;
  }
  if ( window.location.pathname === "/faq") {

    return <Navigate to="/faq" />;
  }
  if (
    (localStorage.getItem("user_type") === null ||
      localStorage.getItem("user_type") === "") &&
    window.location.pathname !== "/login"
  ) {

    return <Navigate to="/get_started" />;
    // return null;
  }
  return <div></div>;
}
