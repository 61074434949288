import {
  SET_ASSETS_LIST,
  SET_ENTITY_LIST,
  SET_ORGANISATION_STRUCTURE,
  SET_PARENT_ENTITY,
  SET_PRODUCTNSERVICE_LIST,
  SET_STAKEHOLDERS_LIST,
  SET_SUPPLIERS_LIST,
  SET_UNITS_LIST,
} from "constants/phase-3/orgDetails/orgDetailsConst";

const initial_state = {
  entity_list: [],
  units_list: [],
  assets_list: [],
  stakeholders_list: [],
  suppliers_list: [],
  product_service_list: [],

  parent_entity: null,
  organisation_structure: null,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case "RESET_STATE":
      return (state = {
        ...state,
        ...initial_state,
      });
    case SET_ENTITY_LIST:
      return (state = {
        ...state,
        entity_list: action.payload,
      });
    case SET_UNITS_LIST:
      return (state = {
        ...state,
        units_list: action.payload,
      });
    case SET_ASSETS_LIST:
      return (state = {
        ...state,
        assets_list: action.payload,
      });
    case SET_STAKEHOLDERS_LIST:
      return (state = {
        ...state,
        stakeholders_list: action.payload,
      });
    case SET_SUPPLIERS_LIST:
      return (state = {
        ...state,
        suppliers_list: action.payload,
      });
    case SET_PRODUCTNSERVICE_LIST:
      return (state = {
        ...state,
        product_service_list: action.payload,
      });

    case SET_PARENT_ENTITY:
      return (state = {
        ...state,
        parent_entity: action.payload,
      });

    case SET_ORGANISATION_STRUCTURE:
      return (state = {
        ...state,
        organisation_structure: action.payload,
      });

    default:
      return state;
  }
}
