import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { emailValidation } from "actions/regexValidation/regexValidation";

const ForgotPasswordDialog = ({ open, onClose, initialEmail }) => {
  const [resetEmail, setResetEmail] = useState(initialEmail);
  useEffect(() => {
    setResetEmail(initialEmail);
  }, [initialEmail]);
  

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="forgot-password-dialog">
      <DialogTitle>
        <Typography variant="h2" fontWeight="light">Reset Password</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontWeight: "20px", marginTop: "6px" }}>
          Please enter your email address to reset your password.
          We will send a reset link to your email address.
        </DialogContentText>
        <TextField
          sx={{ marginTop: "10px" }}
          type="email"
          name="resetEmail"
          value={resetEmail}
          onChange={(e) => setResetEmail(e.target.value)}
          placeholder="Enter your email"
          error={!emailValidation(resetEmail)}
          helperText={!emailValidation(resetEmail) ? "Email is not valid" : ""}
          fullWidth
          required
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          disabled={!emailValidation(resetEmail)}
          onClick={onClose}  
        >
          RESET PASSWORD
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
