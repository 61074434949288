import makeStyles from "@mui/styles/makeStyles";

const userProfileCardUseStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  border: {
    padding: "1px",
    backgroundImage: "linear-gradient(to right, #47BB76, #3374B9)",
    margin: "0px 110px",
    borderRadius: 50,
  },
  name: {
    padding: "0.6rem 2rem 0.5rem 2rem",
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    cursor: "pointer",
  },
  boxstyle: {
    width: "400px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 22px",
  },
  org: {
    fontWeight: 800,
    fontSize: 18,
  },
  tick: {
    color: "#32A05F",
  },
  menu: { marginTop: 35 },
  typo: { fontWeight: 600, marginBottom: 6 },
  divider: { border: " 1px solid #DADBE6", marginBottom: 12 },
  link: { textDecoration: "none" },
  iconDiv: {
    marginTop: 10,
    marginLeft: 20,
    marginBottom: 10,
    display: "flex",
    cursor: "pointer",
  },
  typo1: { marginLeft: 10 },
}));

export default userProfileCardUseStyles;
