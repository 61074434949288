import vector from "images/vector.png";
import settings from "images/settings.png";
// import reportListing from "images/reportListing.png";
// import surveyListing from "images/surveyListing.png";
// import getStarted from "images/getStarted.png";
// import dashboardIcon from "images/dashboardIcon.png";
import getStarted from "images/navDrawerIcon/getStarted.png";
import dashboard from "images/navDrawerIcon/dashboard.png";
import esgMaturity from "images/navDrawerIcon/esgMaturity.png";
import esgStrategy from "images/navDrawerIcon/esgStrategy.png";
import esgCompliance from "images/navDrawerIcon/esgCompliance.png";
import dataHub from "images/navDrawerIcon/datahub.png";

import materialityMapping from "images/navDrawerIcon/materialityMapping.png";
import organisationalStructure from "images/navDrawerIcon/organisationStructure.png";
import survey from "images/navDrawerIcon/survey.png";
import supplier from "images/navDrawerIcon/supplier.png";
import task from "images/navDrawerIcon/task.png";

// Configuration array for the navigation items

const navItems = [
  {
    path: "/get_started",
    icon: getStarted,
    label: "Get Started",
    dataTut: "reactour__get_started",
    userType: ["ADMIN"], // Optional: restrict to specific user types
    index: 1,
    disableStyle: true,
  },
  {
    path: "/dashboard",
    icon: dashboard,
    label: "Dashboard",
    dataTut: "reactour__report_listing",
    userType: ["ADMIN"],
    index: 2,
    disableStyle: true,
  },
  {
    path: "/organisational_structure",
    icon: organisationalStructure,
    label: "Organisational Structure",
    dataTut: "reactour__report_listing",
    userType: ["ADMIN"],
    index: 3,
    disableStyle: true,
  },
  {
    path: "/esg_maturity",
    icon: esgMaturity,
    label: "ESG Maturity",
    dataTut: "reactour__report_listing",
    userType: ["ADMIN"],
    index: 4,
    disableStyle: true,
  },
  {
    path: "/materiality_mapping",
    icon: materialityMapping,
    label: "Materiality Mapping",
    dataTut: "reactour__survery_listing",
    userType: ["ADMIN"],
    index: 5,
    disableStyle: true,
  },
  {
    path: "/esg_strategy",
    icon: esgStrategy,
    label: "ESG Strategy",
    dataTut: "reactour__survery_listing",
    userType: ["ADMIN"],
    index: 6,
    disableStyle: true,
  },

  {
    path: "/esg_compliance",
    icon: esgCompliance,
    label: "ESG Compliance",
    dataTut: "reactour__compliance_listing",
    userType: ["ADMIN"],
    index: 7,
    disableStyle: true,
  },
  {
    path: "/supplier",
    icon: supplier,
    label: "Supplier Assessment",
    dataTut: "reactour__supplier_listing",
    userType: ["ADMIN"],
    index: 8,
    disableStyle: true,
  },
  {
    path: "/risk_admin",
    icon: supplier,
    label: "Risk",
    dataTut: "reactour__supplier_listing",
    userType: ["RE"],
    index: 8,
    disableStyle: true,
  },
  {
    path: "/task",
    icon: task,
    label: "My Task",
    dataTut: "reactour__suplier_task_listing",
    userType: ["ADMIN", "GENERAL"],
    index: 9,
    disableStyle: true,
  },
  {
    path: "/survey",
    icon: survey,
    label: "Survey",
    dataTut: "reactour__supplier_admin_survery_listing",
    userType: ["SUPPLIER_ADMIN"],
    index: 14,
    disableStyle: true,
  },
  {
    path: "/task",
    icon: task,
    label: "Task",
    dataTut: "reactour__supplier_admin_task_listing",
    userType: ["SUPPLIER_GENERAL", "SUPPLIER_ADMIN"],
    index: 9,
    disableStyle: true,
  },

  {
    path: "/survey",
    icon: survey,
    label: "Survey",
    dataTut: "reactour__survery_listing",
    userType: ["ADMIN"],
    index: 10,
    disableStyle: true,
  },
  //   {
  //     path: "/survey_listing",
  //     icon: surveyListing,
  //     label: "Assessments",
  //     dataTut: "reactour__survery_listing",
  //     userType: ["SA"],
  //     index: 6,
  //     disableStyle: true,
  //   },
  //   {
  //     path: "/report_listing",
  //     icon: reportListing,
  //     label: "Tasks",
  //     dataTut: "reactour__report_listing",
  //     userType: ["A", "SA"],
  //     index: 3,
  //     disableStyle: true,
  //   },
  {
    path: "/data_hub",
    icon: dataHub,
    label: "Data Hub",
    dataTut: "reactour__report_listing",
    userType: ["ADMIN"],
    index: 11,
    disableStyle: true,
  },
  {
    path: "/general_settings",
    icon: settings,
    label: "General Settings",
    dataTut: "reactour__general_settings",
    userType: ["ADMIN", "SUPPLIER_GENERAL", "SUPPLIER_ADMIN"],
    index: 12,
    disableStyle: false, // General settings should not have disabled style
  },
];

export default navItems;

//old nav items ,
// const navItems = [
//     {
//       path: "/get_started",
//       icon: getStarted,
//       label: "Get Started",
//       dataTut: "reactour__get_started",
//       userType: ["A", "SA"], // Optional: restrict to specific user types
//       index: 12,
//       disableStyle: true,

//     },
//     {
//       path: "/dashboard_static",
//       icon: dashboardIcon,
//       label: "Dashboard Static",
//       dataTut: "reactour__report_listing",
//       userType: ["A", "SA"],
//       index: 5,
//       disableStyle: true,

//     },
//     {
//       path: "/survey_listing",
//       icon: surveyListing,
//       label: "Assessments",
//       dataTut: "reactour__survery_listing",
//       userType: ["SA"],
//       index: 6,
//       disableStyle: true,

//     },
//     {
//       path: "/report_listing",
//       icon: reportListing,
//       label: "Tasks",
//       dataTut: "reactour__report_listing",
//       userType: ["A", "SA"],
//       index: 3,
//       disableStyle: true,

//     },
//     {
//       path: "/data_hub/sheets_import",
//       icon: vector,
//       label: "Data Hub",
//       dataTut: "reactour__report_listing",
//       userType: ["SA"],
//       index: 1,
//       disableStyle: true,
//     },
//     {
//       path: "/general_settings",
//       icon: settings,
//       label: "General Settings",
//       dataTut: "reactour__general_settings",
//       index: 8,
//       disableStyle: false, // General settings should not have disabled style
//     },
//   ];
