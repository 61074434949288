import {
  VIEW_MEMBERS_ASSESSMENT,
  SET_COMMENT_STORE,
  SET_COMMENT,
  SET_TOPIC_ROLE,
} from "../../constants/reportListing/reportListingConst";
import { SET_ASSIGNED_ASSESSMENTS_TOTAL_COUNT } from "../../constants/surveyListing/surveyListingConst";

const initial_state = {
  assessments: [],
  commentStore: [],
  comment: "",
  currentTopicRole: "",
  totalAssignedAssessmentCount: 0,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case VIEW_MEMBERS_ASSESSMENT:
      return (state = { ...state, assessments: action.payload });
    case SET_COMMENT_STORE:
      return (state = { ...state, commentStore: action.payload });
    case SET_COMMENT:
      return (state = { ...state, comment: action.payload });
    case SET_TOPIC_ROLE:
      return (state = { ...state, currentTopicRole: action.payload });
    case SET_ASSIGNED_ASSESSMENTS_TOTAL_COUNT:
      return (state = {
        ...state,
        totalAssignedAssessmentCount: action.payload,
      });
    default:
      return state;
  }
}
