import React from "react";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import makeStyles from "@mui/styles/makeStyles";
import DrawerListItem from "../listItem/drawerListItem";
import logo from "../../../images/ImpactGrows Black corrected.svg";
import { Link } from "react-router-dom";
import menuBarUseStyles from "./menuBarStyles";

function MobileDrawer({ logOut, token, user_type }) {
  const classes = menuBarUseStyles();
  const [show, setShow] = React.useState(false);

  const toggleDrawer = () => () => {
    setShow(!show);
  };

  const [selectedIndex, setSelectedId] = React.useState(0);
  const setSelectedIndex = (index) => {
    setSelectedId(index);
  };

  const list = () => (
    <div onClick={toggleDrawer()}>
      <List>
        <DrawerListItem>
          <ListItemIcon>
            <>
              <Icon>notifications</Icon>
            </>
          </ListItemIcon>
          {<ListItemText secondary="Notification" />}
        </DrawerListItem>
      </List>

      <Divider className={classes.divider} />
      <List className={classes.list2}>
        {(user_type === "A" || user_type === "SA") && (
          <Link to="/survey_listing" className={classes.link}>
            <DrawerListItem
              selected={selectedIndex === 6}
              className={classes.listitems}
              onClick={() => setSelectedIndex(6)}
              button
            >
              <ListItemIcon>
                <Icon fontSize="small">feed</Icon>
              </ListItemIcon>
              <ListItemText secondary="Assessments" />
            </DrawerListItem>
          </Link>
        )}
        {user_type === "SA" && (
          <Link to="/report_listing" className={classes.link}>
            <DrawerListItem
              selected={selectedIndex === 3}
              className={classes.listitems}
              onClick={() => setSelectedIndex(3)}
              button
            >
              <ListItemIcon>
                <Icon fontSize="small">assessment</Icon>
              </ListItemIcon>
              <ListItemText secondary="Reports" />
            </DrawerListItem>
          </Link>
        )}

        <DrawerListItem
          selected={selectedIndex === 8}
          className={classes.listitems}
          onClick={() => setSelectedIndex(8)}
          button
        >
          <ListItemIcon>
            <Icon fontSize="small">settings</Icon>
          </ListItemIcon>
          <ListItemText secondary="General Settings" />
        </DrawerListItem>

        <DrawerListItem
          selected={selectedIndex === 9}
          className={classes.listitems}
          onClick={() => setSelectedIndex(9)}
          button
        >
          <ListItemIcon>
            <Icon fontSize="small">help_outlined</Icon>
          </ListItemIcon>
          <ListItemText secondary={"Help & Supports"} />
        </DrawerListItem>
      </List>

      <Divider className={classes.divider} />
      <List className={classes.list}>
        <Link to="/login">
          <DrawerListItem
            selected={selectedIndex === 10}
            className={classes.listitems}
            onClick={() => {
              setSelectedIndex(10);
              logOut(token);
            }}
            button
          >
            <ListItemIcon>
              <Icon fontSize="small">logout</Icon>
            </ListItemIcon>
            <ListItemText secondary="Logout" />
          </DrawerListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div className={classes.hideShow}>
      <Grid container alignItems="center" className={classes.mainGrid}>
        <Grid item sm={2}>
          <IconButton onClick={toggleDrawer()} size="large">
            <Icon className={classes.icon}>menu</Icon>
          </IconButton>
          <Drawer anchor="left" open={show} onClose={toggleDrawer()}>
            {list("right")}
          </Drawer>
        </Grid>
        <Grid item sm={2}>
          <img src={logo} alt="" className={classes.img} />
        </Grid>
      </Grid>
    </div>
  );
}
export default React.memo(MobileDrawer);
