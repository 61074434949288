import {
  SET_ADD_CHAT,
  SET_CHAT_GROUP,
  SET_CHATS_CONVERSATION,
  SET_SELECTED_GPT_CHAT,
  SET_FOLLOW_UP_QUESTION,
} from "constants/phase-3/gptWise/gptWiseConst";

const initial_state = {
  // Chats list
  selectedChat: null,
  chatsList: [],

  // Conversation
  chats: [],

  // follow up questions
  followUpQues: "",
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case "RESET_STATE":
      return (state = {
        ...state,
        ...initial_state,
      });
    // Chats List
    case SET_SELECTED_GPT_CHAT:
      return (state = {
        ...state,
        selectedChat: action.payload,
      });

    case SET_CHAT_GROUP:
      return (state = {
        ...state,
        chatsList: action.payload,
      });

    // Conversation
    case SET_CHATS_CONVERSATION:
      return (state = {
        ...state,
        chats: action.payload,
      });

    case SET_ADD_CHAT:
      const chatToUpdate = state.chats.find(
        (chat) => chat._id === action.payload._id
      );
      if (chatToUpdate) {
        const updatedChat = { ...chatToUpdate, ...action.payload };
        const updatedChats = state.chats.map((chat) =>
          chat._id === action.payload._id ? updatedChat : chat
        );
        return { ...state, chats: updatedChats };
      }
      // If chat with given _id not found, simply return the current state
      return state;

    // Follow Up Questions
    case SET_FOLLOW_UP_QUESTION:
      return (state = {
        ...state,
        followUpQues: action.payload,
      });

    default:
      return state;
  }
}

// ------------------- Important Key Points -------------------
