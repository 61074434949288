export const RESET_ESG_STRATEGY = "RESET_ESG_STRATEGY";
export const SET_TOPIC_SELECTED = "SET_TOPIC_SELECTED";
export const SET_TOPIC_DETAILS = "SET_TOPIC_DETAILS";
export const SET_TOPIC_STATE = "SET_TOPIC_STATE";
export const SET_CURRENT_TOPIC = "SET_CURRENT_TOPIC";
export const SET_IS_EDIT_ACTIVITY = "SET_IS_EDIT_ACTIVITY";
export const SET_IS_EDIT_KPI = "SET_IS_EDIT_KPI";

export const SET_ASSIGNED_TOPICS = "SET_ASSIGNED_TOPICS";
export const SET_ASSIGNED_MEMBERS = "SET_ASSIGNED_MEMBERS";
export const SET_UNITS = "SET_UNITS";
