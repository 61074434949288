import React from "react";
import Checkbox from "@mui/material/Checkbox";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material/styles";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
const greenTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: { main: "#47BB76" },
      secondary: { main: "#3374B9" },
    },
    overrides: {
      MuiCheckbox: {
        colorPrimary: {
          color: "#47BB76",
        },
        colorSecondary: {
          color: "#3374B9",
        },
      },
    },
  })
);

export default class CustomCheckBox extends React.PureComponent {
  render() {
    return (
      <>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={greenTheme}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              checked={this.props.checked}
              {...this.props}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </>
    );
  }
}
