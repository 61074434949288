import makeStyles from "@mui/styles/makeStyles";

const privacyUseStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: "250px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "260px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "260px",
    },
  },
  paddings: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  leftGrid: {
    marginLeft: "150px",
    paddingLeft: "0.5%",
    paddingTop: "80px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
    },
  },
  texts: {
    marginTop: "10px",
    fontFamily: "Poppins",
    fontWeight: "600",
    color: "rgba(51, 116, 185, 0.8)",
    fontStyle: "normal",
    margin: "25px 0px",
  },
  name: {
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "23px",
    marginLeft: "-19px",
    paddingLeft: "22px",
    borderRadius: "30px",
    cursor: "pointer",
  },
  grid1: { marginTop: "50px", marginLeft: "-20px" },
  Accordion: {
    boxShadow: "0px 0px 0px 0px",
    borderBottom: "0.5px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "0px",
  },
  Accordion1: { padding: "12px" },
}));
export default privacyUseStyles;
