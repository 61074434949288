import React from "react";
import ListItem from "@mui/material/ListItem";
import drawerListitemUseStyles from "./drawerListitemStyles";

function DrawerListItem(props) {
  const classes = drawerListitemUseStyles();
  return (
    <>
      <ListItem
        {...props}
        classes={{ root: classes.root, selected: classes.selected }}
      />
    </>
  );
}
export default React.memo(DrawerListItem);
