import {
  SET_EXCEL_FILE_DATA,
  SET_EXCEL_FILES,
  SET_KPI_TAGS,
  SET_ORGANIZATIONAL_TAGS,
  SET_SELECTED_KPI,
  SET_DATAHUB_DATASET,
  SET_DATASET_KPI_YEAR,
  SET_DATAHUB_DATASET_KPI_DATA,
  SET_DATAHUB_TAB,
} from "constants/phase-3/datahub/datasetConst";

const initial_state = {
  selectedKPI: null,
  organizationalTags: [],
  kpiTags: [],
  excelFiles: [],
  excelFileData: null,
  dataSetTable: [],
  dataSetKPIYear: null,
  dataSetKPIData: null,

  dataHubTab: 0,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case "RESET_STATE":
      return (state = {
        ...state,
        ...initial_state,
      });
    case SET_SELECTED_KPI:
      return (state = {
        ...state,
        selectedKPI: action.payload,
      });

    case SET_ORGANIZATIONAL_TAGS:
      return (state = {
        ...state,
        organizationalTags: action.payload,
      });

    case SET_KPI_TAGS:
      return (state = {
        ...state,
        kpiTags: action.payload,
      });

    case SET_EXCEL_FILES:
      return (state = {
        ...state,
        excelFiles: action.payload,
      });

    case SET_EXCEL_FILE_DATA:
      return (state = {
        ...state,
        excelFileData: action.payload,
      });
    case SET_DATAHUB_DATASET:
      return (state = {
        ...state,
        dataSetTable: action.payload,
      });

    case SET_DATASET_KPI_YEAR:
      return (state = {
        ...state,
        dataSetKPIYear: action.payload,
      });

    case SET_DATAHUB_DATASET_KPI_DATA:
      return (state = {
        ...state,
        dataSetKPIData: action.payload,
      });

    case SET_DATAHUB_TAB:
      return (state = {
        ...state,
        dataHubTab: action.payload,
      });

    default:
      return state;
  }
}
