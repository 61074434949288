// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
          borderRadius: "8px",
        },
        sizeLarge: {
          height: 48,
        },
        // contained
        contained: {
          padding: "5px 25px 5px 25px",
        },
        outlined: {
          color: theme.palette.text.primary,
          padding: "5px 20px",
          borderWidth: "2px",
          "&:hover": {
            borderWidth: "2px",
          },
        },
        containedInherit: {
          color: theme.palette.grey[800],
          // boxShadow: theme.customShadows.z8,
          "&:hover": {
            backgroundColor: theme.palette.grey[400],
          },
        },
        outlinedSecondary: {
          color: theme.palette.secondary.main,
          padding: "5px 20px",
          borderWidth: "2px",
          "&:hover": {
            borderWidth: "2px",
          },
        },
        containedPrimary: {
          // boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          // boxShadow: theme.customShadows.secondary,
        },
        containedInfo: {
          // boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          // boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          // boxShadow: theme.customShadows.warning,
        },
        containedError: {
          // boxShadow: theme.customShadows.error,
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_80]}`,
          "&:hover": {
            border: `1px solid ${theme.palette.grey[500]}`,
            // backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        startIcon: {
          marginRight: "10px",
        },
        endIcon: {
          marginLeft: "10px",
        },
      },
    },
  };
}
