import React from "react";
import Radio from "@mui/material/Radio";
import clsx from "clsx";
import radioButtonUseStyles from "./radioButtonStyles";

function RadioButton(props) {
  const classes = radioButtonUseStyles();
  return (
    <>
      <Radio
        {...props}
        size="small"
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
      />
    </>
  );
}
export default React.memo(RadioButton);
