import axios from "./axios";

// redux
import { refresh_token } from "actions/phase-3/auth/authAction";

// for redux store
let store;

export const injectStore = (_store) => {
  store = _store;
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    const { dispatch } = store;

    const { isLoggedin } = store.getState().user;

    // Check if the error is an unauthorized error
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      isLoggedin
    ) {
      originalRequest._retry = true;

      try {
        // Attempt to refresh the token
        const token = await dispatch(refresh_token());

        // If successful, add the new token to the request headers
        originalRequest.headers["Authorization"] = "Bearer " + token;

        // Resend the original request with the new token
        return axios(originalRequest);
      } catch (refreshError) {
        // If refresh token fails, logout user
        alert("Token expired, Please login again...");
        // dispatch(LogoutUser());
      }
    }

    // If the error is not unauthorized or refresh token fails, just throw the error
    return Promise.reject(
      (error.response && error.response.data) || "Axios - Something went wrong"
    );
  }
);
