import { Typography } from "@mui/material";

const requiredField = (show) => {
  return show ? (
    <Typography component="span" color="error.main">
      *
    </Typography>
  ) : (
    ""
  );
};

export default requiredField;
