import makeStyles from "@mui/styles/makeStyles";

const profileCardUseStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  border: {
    padding: "1px",
    backgroundImage: "linear-gradient(to right, #47BB76, #3374B9)",
    margin: "0px 110px",
    borderRadius: 50,
  },
  name: {
    padding: "0.6rem 2rem 0.5rem 2rem",
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    cursor: "pointer",
  },
  boxstyle: {
    width: "400px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 22px",
  },
  org: {
    fontWeight: 800,
    fontSize: 18,
  },
  tick: {
    color: "#32A05F",
  },
  typo: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  typo1: { margin: "50px 10px 50px 10px" },
  grid1: { marginLeft: 30 },
  typo2: { fontWeight: 600, marginBottom: 6 },
  CustomButton: { paddingLeft: 50, paddingRight: 50 },
  link: { textDecoration: "none" },
  Menu: { marginTop: 35, marginRight: 40 },
  mainDiv: { border: " 1px solid #DADBE6", marginBottom: 12 },
  companyText: { cursor: "pointer" },
  dividerDiv: { border: " 1px solid #DADBE6", marginTop: "12px" },
  subDiv: { marginTop: 10, display: "flex", justifyContent: "center" },
  typo: {
    marginLeft: 10,
    border: "2px solid #32A05F",
    padding: 10,
    borderRadius: 25,
    cursor: "pointer",
  },
  boxTypo: { color: "#77787B", fontSize: 11 },
}));

export default profileCardUseStyles;
