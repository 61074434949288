import { SET_TEST_DATA } from "constants/phase-3/test/testConstants";

const initial_state = {
  testData: null,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case "RESET_STATE":
      return (state = {
        ...state,
        ...initial_state,
      });
    case SET_TEST_DATA:
      return (state = {
        ...state,
        testData: action.payload,
      });

    default:
      return state;
  }
}
