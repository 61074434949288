import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

export default class Loader extends React.PureComponent {
  render() {
    const { loader } = this.props;
    return (
      <Dialog open={loader.open} aria-labelledby="Loading...">
        <DialogTitle id="simple-dialog-title">
          <CircularProgress />
        </DialogTitle>
      </Dialog>
    );
  }
}
