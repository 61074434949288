import makeStyles from "@mui/styles/makeStyles";

const faqUseStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: "12%",
    [theme.breakpoints.down("xl")]: {
      marginTop: "200px",
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "200px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "260px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "280px",
    },
  },
  paddings: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  leftGrid: {
    marginLeft: "150px",
    paddingLeft: "0.5%",
    paddingTop: "80px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
    },
  },
  texts: {
    marginTop: "10px",
    fontFamily: "Poppins",
    fontWeight: "600",
    color: "rgba(51, 116, 185, 0.8)",
    fontStyle: "normal",
  },
  name: {
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "23px",
    marginLeft: "-19px",
    paddingLeft: "22px",
    borderRadius: "30px",
    cursor: "pointer",
  },
}));

export default faqUseStyles;
